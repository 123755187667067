import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";
import Modal from "react-modal";
import ReactLoading from "react-loading";

//styles
const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;
	.remove {
		display: none;
	}
	@media screen and (max-width: 1300px) {
		width: 900px;
	}

	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		text-align: start;
		font-size: 14px;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: start;
		font-size: 13px;

		a {
			color: #43425d;
		}
	}
`;
const CloseButton = styled.button`
	background-color: transparent;
	border-radius: 50%;
	padding: 0.2rem;
	border: none;
	outline: none;
	margin-bottom: 1rem;
	font-size: 1rem;
`;
const ActionButton = styled.button`
	width: 25%;
	font-size: 0.8rem;
	margin: 1rem 0;
	padding: 0.4rem 0.2rem;
	border-radius: 10%;
	border: none;
	outline: none;
	outline: none;
	color: #3b86ff;
	&:first-of-type {
		margin-right: 1rem;
		border: 1px solid #3b86ff;
	}
	&:last-of-type {
		background-color: #3b86ff;
		color: white;
	}
`;
const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};
const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const AdministratorsTable = ({ admins, setisEditing, loading }) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [adminId, setadminId] = useState("");
	const adminInfo = JSON.parse(localStorage.getItem("admin"));
	function openModal() {
		setIsOpen(true);
	}

	function DeleteModal() {
		function closeModal() {
			setIsOpen(false);
		}

		return (
			<div>
				<Modal
					isOpen={modalIsOpen}
					// onAfterOpen={afterOpenModal}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<div style={{ textAlign: "end" }}>
						<CloseButton onClick={closeModal}>X</CloseButton>
					</div>
					<div>Are you sure you want to delete </div>
					<div
						style={{ width: "100%", display: "flex", justifyContent: "center" }}
					>
						<ActionButton onClick={() => deleteAdmin(adminId)}>
							Yes
						</ActionButton>
						<ActionButton onClick={closeModal}>Cancel</ActionButton>
					</div>
				</Modal>
			</div>
		);
	}
	const deleteAdmin = (id) => {
		axios
			.delete(`admin/delete/${id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => {
				// console.log(response);
				toast.success("Admin Deleted");
				window.location.reload();
			})
			.catch((err) => {
				console.log("Err", err);
				toast.error("Error, try later");
			});
	};

	return (
		<>
			<Table>
				<tr>
					<th style={{paddingLeft: "2rem"}}>#</th>
					{/* <th>ADMIN ID</th> */}
					<th>FULL NAME</th>
					<th>PHONE NO</th>
					<th>USERNAME</th>
					<th>ROLE</th>
					<th>DATE CREATED</th>
					{adminInfo?.role === "Super Admin" ? <th>ACTIONS</th> : null}
				</tr>

				{admins.map((admin, index) => {
					return (
						<tr>
							<td style={{paddingLeft: "2rem"}}>{index + 1}</td>
							{/* <td>{admin.adminid}</td> */}
							<td style={{textTransform: "capitalize"}}>{admin.fullname}</td>
							<td>{admin.phone_number}</td>
							<td>{admin.username}</td>
							<td>{admin.role}</td>
							<td>{admin.created_at}</td>
							<td
								className={adminInfo?.role === "Super Admin" ? null : "remove"}
							>
									<Link
										to={`/admin/administrators/edit/${admin.id}`}
										style={{ marginRight: "10px" }}
										onClick={() => {
											this?.setisEditing(true);
											console.log(setisEditing);
											localStorage.setItem("Edit", "yes");
										}}
									>
										{" "}
										<Edit />{" "}
									</Link>
								
								<Delete
									onClick={() => {
										openModal();
										setadminId(admin?.id);
									}}
								/>
							</td>
						</tr>
					);
				})}
				<Load loading={loading}>
					<ReactLoading type={"spin"} color="#4D4F5C" />
				</Load>
				<ToastContainer
					className="toast"
					position="top-right"
					autoClose="4000"
					hideProgressBar="false"
					closeOnClick
				></ToastContainer>
			</Table>
			<DeleteModal />
		</>
	);
};

export default AdministratorsTable;
