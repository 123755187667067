import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Scrollspy from 'react-scrollspy'

//styles
const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 85px);
    background: #fff;
    position: fixed;
    z-index: 99;
    top: 85px;
    right: 0;
    transition: .1s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    padding: 2rem 5rem;
    /* margin-top: 75px; */

    @media screen and (max-width: 600px) {
        padding: 2rem 4rem;
    }

    @media screen and (max-width: 500px) {
        padding: 2rem 3rem;
    }

    @media screen and (max-width: 450px) {
        padding: 2rem 2rem;
    }
`

const MenuLink = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 8px 10px;
    cursor: pointer;
    position: relative;
    margin-bottom: 20px;
    
    a {
        color: #000;
    }
`

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 25px;
    background: #142251;
    border-radius: 3px;
    font-size: 14px;
    width: 200px;
`

const Sidebar = ({ isOpen, toggle }) => {
    let admin = localStorage.getItem("admin-token");
	let user = localStorage.getItem("token");
    return (
    
        <Container  isOpen={isOpen} onClick={toggle}>
            <Scrollspy className='sidebar-scrollspy'
            items={ ['home', 'about', 'services', 'news', 'gallery', 'contact'] } currentClassName="isCurrent"
            >
                <MenuLink onClick={() => window.location.replace('#home')}><a href='#home'>Home </a></MenuLink>
                <MenuLink onClick={() => window.location.replace('#about')}><a href='#about'>About Us </a></MenuLink>
                <MenuLink onClick={() => window.location.replace('#services')}><a href='#services'>Our Services </a></MenuLink>
                <MenuLink onClick={() => window.location.replace('#news')}><a href='#news'>News </a></MenuLink>
                <MenuLink onClick={() => window.location.replace('#gallery')}><a href='#gallery'>Gallery </a></MenuLink>
                <MenuLink onClick={() => window.location.replace('#contact')}><a href='#contact'>Contact Us </a></MenuLink>
                
                {admin ? (
						<Button to="/admin">Admin</Button>
					) : user ? (
						<Button to="/dashboard">Profile</Button>
					) : (
						<Button to="/login">Log in</Button>
					)}
            </Scrollspy>
        </Container>
    )
}

export default Sidebar
