import React from "react";
// import { Link } from 'react-router-dom'
import styled from "styled-components/macro";
import barrel from "../../assets/barrel.png";
import mover from "../../assets/mover.png";
import bg from "../../assets/servicesbg.svg";
import "./styles.css";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 4rem 0;

	h2 {
		position: relative;
		margin-bottom: 25px;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			height: 3px;
			background: #f92524;
			width: 55px;
			margin-left: 50%;
			transform: translateX(-50%);
		}
	}

	p {
		color: #7a839e;
		font-size: 1rem;
		margin-bottom: 15px;
		width: 70%;
		text-align: justify !important;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 300px;
	margin-top: 25px;
	background-image: url(${bg});
	background-size: cover;
	padding: 0 10rem;

	@media screen and (max-width: 1200px) {
		padding: 0 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 0 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 0 6rem;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
		padding: 0 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 0 4rem;
	}
	@media screen and (max-width: 545px) {
		grid-template-columns: 1fr;
		grid-template-rows: auto 400px auto 400px auto;
	}
	@media screen and (max-width: 500px) {
		padding: 0 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 0 2rem;
	}

	img {
		width: 100%;
		height: 100%;
	}

	div {
		text-align: left;
		padding: 2rem 1rem 0;

		h4 {
			color: #f92524;
			font-weight: 700;
			margin-bottom: 5px;
		}

		h5 {
			font-weight: 200;
			color: #7a839e;
			margin-bottom: 12px;
		}

		p {
			padding: 1rem 0;
			border-top: 2px solid #bbb;
			font-size: 1rem;
			width: 100%;
		}
	}
`;

const Services = () => {
	return (
		<Container id="services">
			<h2>Our Services</h2>
			<p>
				The company leverages on its highly trained crew of inspection agents
				and cutting-edge information technology, such as daily satellite imagery
				and AIS data to provide export reports and bespoke investigations backed
				by data evidence.
			</p>

			<Grid>
				<img src={barrel} alt="barrel" className="barrel1" />

				<div>
					<h4>Monitoring</h4>
					{/* <h5>highlight</h5> */}
					<p>Crude oil export monitoring and inspection</p>
				</div>

				<img src={barrel} alt="barrel" className="barrel2" />

				<div>
					<h4>Research Services</h4>
					{/* <h5>highlight</h5> */}
					<p>
						Inspection agent and independent surveyors’ management and training
					</p>
				</div>

				<img src={mover} alt="barrel" />

				<div>
					<h4>Reporting</h4>
					{/* <h5>highlight</h5> */}
					<p>
						Environmental and Pollution reporting
						<br />
						Export discrepancy reporting and investigations
					</p>
				</div>
			</Grid>
		</Container>
	);
};

export default Services;
