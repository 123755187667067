import React from "react";
import styled from "styled-components/macro";
import bg from "../../assets/aboutbg.png";
import { ReactComponent as Open } from "../../assets/open.svg";
import { ReactComponent as Fully } from "../../assets/fully.svg";
import { ReactComponent as Personal } from "../../assets/personal.svg";

//styles
const Container = styled.section`
	display: flex;
	padding: 2rem 2rem 0 0;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		padding: 2rem 8rem 0 0;
	}
	@media screen and (max-width: 1000px) {
		padding: 2rem 7rem 0 0;
	}

	@media screen and (max-width: 920px) {
		padding: 2rem 6rem 0 0;
	}
	@media screen and (max-width: 768px) {
		padding: 2rem 5rem;
		flex-direction: column;
	}
	@media screen and (max-width: 600px) {
		padding: 2rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 2rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 2rem 2rem;
	}
`;

const Bg = styled.div`
	width: 50%;
	height: 600px;
	position: relative;

	@media screen and (max-width: 768px) {
		order: 2;
		width: 100%;
		height: 500px;
	}

	@media screen and (max-width: 420px) {
		height: 400px;
	}

	img {
		height: 100%;
		width: 100%;
	}
`;

export const Texts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 45%;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	h2 {
		position: relative;
		margin-bottom: 25px;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			height: 3px;
			background: #f92524;
			width: 55px;
		}
	}

	p {
		color: #7a839e;
		font-size: 1rem;
		margin-bottom: 15px;
		text-align: justify;
	}
`;

export const Group = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	text-align: start;

	svg {
		width: 10%;
		height: 55px;
		margin-right: 15px;
		height: 60px;
	}

	div {
		width: 85%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		h5 {
			margin-bottom: 5px;
			font-weight: 600;
		}

		p {
			font-size: 1rem;
		
		}
	}
`;


const About = () => {
	return (
		<Container id="about">
			<Bg>
				{" "}
				<img src={bg} alt="bg" />{" "}
			</Bg>

			<Texts>
				<h2>About Us</h2>
				<p>
					Kephren Business Nigeria Limited is a private limited liability
					company incorporated under the Companies and Allied Matters Act of
					Nigeria. It was incorporated in 2008 by a team of Nigerian
					Professionals with over 25 years of experience in different sectors of
					the Nigerian Economy. Kephren is a 100% Nigerian owned company, with
					significant experience in the Nigerian Maritime, Crude Oil and Gas
					industry.
				</p>

				<Group>
					<Open style={{ width: "40px" }} />
					<div>
						{/* <h5>Open Door Policy</h5> */}
						<p>
							Over the years, Kephren Business Nigeria Ltd has developed the
							manpower, capacity, and professionalism to become an industry
							leader in providing reliable and effective Oil and Gas monitoring
							and Evaluation services for clients.
						</p>
					</div>
				</Group>

				<Group>
					<Fully style={{ width: "40px" }} />
					<div>
						{/* <h5>Fully Insured</h5> */}
						<p>
							Our surveyors are trained and equipped for Production and Export
							data collation and testing of crude oil on offshore and land
							terminals. We also offer pollution monitoring of oil and gas at
							the export zones. Our surveyors go through continuous training and
							consultancy in crude Oil and Gas Inspection in Nigeria.
						</p>
					</div>
				</Group>

				<Group>
					<Personal style={{ width: "40px" }} />
					<div>
						{/* <h5>Personal Trainer</h5> */}
						<p>
							Kephren leverages the latest technology platforms, such as daily
							satellite imagery and real time AIS data to provide clients with
							real time analysis of ongoing events, maritime crude oil export
							reports and bespoke investigations at both the offshore and
							onshore crude oil and gas export terminals.
						</p>
					</div>
				</Group>
			</Texts>
		</Container>
	);
};

export default About;
