import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import "../../App.css";
import axios from "axios";

//components
import Sidebar from "../../components/DashboardSidebars/Admin";
import Topbar from "../../components/DashboardTopbars/Admin";
import ReceivedDocDetails from "../../components/ReceivedDocDetails/Admin.jsx";

const Container = styled.section`
	display: flex;
	height: 100vh;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 2rem 2rem 2rem 5rem;
	overflow: scroll;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 200;

		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 15px;
	height: 100%;
`;

const NaRe = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;

	@media screen and (max-width: 665px) {
		flex-direction: column;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #3b86ff;
		color: #fff;
		font-size: 13px;
		padding: 6px 15px;
		border-radius: 3px;
	}
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 100px 100px 100px;
	border: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	padding: 0 15px;
	margin-bottom: 5px;

	@media screen and (max-width: 660px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: none;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Details = styled.div``;

const AdminUniqueReceivedDoc = () => {
	let history = useHistory();
	const { id } = useParams();
	const [docInfo, setdocInfo] = useState({});
	const [toggle, setToggle] = useState(1);

	const changeTab = (index) => {
		setToggle(index);
	};

	const uniqueDocInfo = () => {
		// const adminInfo = JSON.parse(localStorage.getItem("user"));
		// const adminId = adminInfo?.id;
		axios
			.get(`admin/getoneadmin-received-docs/${id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => {
				console.log(response.data);

				console.log(response.data.receiver_documents);
				setdocInfo(response.data.receiver_documents[0]);
				const singleData = response.data.receiver_documents[0];
				setdocInfo(singleData);
				console.log(docInfo);
			})
			.catch((err) => {
				console.log(err);
				if (err) {
					history.push("/admin-login");
				}
			});
	};

	useEffect(() => {
		const user = localStorage.getItem("admin-token");
		uniqueDocInfo();
		if (user === "undefined" || !user) {
			history.push("/admin-login");
		}
	}, []);

	return (
		<Container>
			<Sidebar />

			<Contents>
				<Topbar />

				<Board>
					<h2>Secured Document Exchange Portal</h2>

					<Info className="info">
						<NaRe>
							<h5>Ministry of Finance Documents</h5>

							{/* <div>Respond</div> */}
						</NaRe>

						<Btns>
							<Button
								className={toggle === 1 ? "btn active-btn" : "btn"}
								onClick={() => changeTab(1)}
							>
								Current
							</Button>
							{/* <Button className={ toggle === 2 ? 'btn active-btn' : 'btn'} onClick={() => changeTab(2)}>Reply 1</Button>
                            <Button className={ toggle === 3 ? 'btn active-btn' : 'btn'} onClick={() => changeTab(3)}>Reply 2</Button>                 */}
						</Btns>

						<Details
							className={toggle === 1 ? "content active-content" : "content"}
						>
							<ReceivedDocDetails docInfo={docInfo} />
						</Details>

						{/* <Details className={ toggle === 2 ? 'content active-content' : 'content'}>
                            <ReceivedDocDetails />
                        </Details>

                        <Details className={ toggle === 3 ? 'content active-content' : 'content'}>
                            <ReceivedDocDetails />
                        </Details> */}
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default AdminUniqueReceivedDoc;
