import React, { useState } from "react";
// import { Link } from 'react-router-dom'
import styled from "styled-components/macro";

import DashboardSidebar from "../../components/DashboardSidebars/Admin";

import { AiOutlineMenu } from "react-icons/ai";

//images
import avatar from "../../assets/Avatar.png";

//svgs
import { ReactComponent as Down } from "../../assets/icons/arrowdown.svg";

//styles
const Topbar = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	padding: 10px 2rem;
	background: #fff;
	z-index: 100;

	@media screen and (max-width: 620px) {
		padding: 10px 2rem;
	}

	input {
		font-size: 13px;
		font-weight: 300;
		color: #4d4f5c;
		outline: none;
		border: none;
		width: 250px;

		@media screen and (max-width: 400px) {
			display: none;
		}
	}
`;

const MenuIcon = styled(AiOutlineMenu)`
	display: none;

	@media screen and (max-width: 600px) {
		display: flex;
		width: 25px;
		height: 25px;
		color: #000;
	}
`;

const Right = styled.div`
	display: flex;

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 2px solid #f0f0f7;

		@media screen and (max-width: 680px) {
			display: none;
		}

		svg {
			margin-right: 20px;

			@media screen and (max-width: 620px) {
				margin-right: 15px;
			}
		}
	}
`;

const Profile = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		border-right: none;
	}

	h3 {
		margin-left: 20px;
		font-weight: 300;
		font-size: 14px;

		@media screen and (max-width: 820px) {
			display: none;
		}
	}
`;

const Dp = styled.section`
	width: 35px;
	height: 35px;
	border-radius: 50%;
	overflow: hidden;

	@media screen and (max-width: 820px) {
		margin-left: 10px;
	}

	img {
		width: 100%;
		height: 100%;
	}
`;
const Dropdown = styled.div`
	display: flex;
	display: none;
	flex-direction: column;
	position: absolute;
	bottom: -100px;
	right: 0;
	background: #43425d;
	padding: 1rem;
	border-radius: 3px;
	visibility: ${({ dropdown }) => (dropdown ? "visible" : "hidden")};

	a {
		font-size: 13px;
		margin-bottom: 10px;
	}
`;
const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	border-radius: 3px;
	cursor: pointer;
	color: #fff;
	background: transparent;
	font-size: 13px;
	background: #3b86ff;
`;

const DashboardTopbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [dropdown, setDropdown] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const showDropdown = () => {
		setDropdown(!dropdown);
	};

	const logout = () => {
		localStorage.removeItem("admin-token");
	};

	const admin = JSON.parse(localStorage.getItem("admin"));
	const adminName = admin.adminid;

	return (
		<>
			<Topbar>
				<MenuIcon onClick={toggle} isOpen={isOpen} />
				<input
					type="text"
					// placeholder="Search transactions, invoices or help"
				/>

				<Right>
					<div>
						{/* <Pf />
                        <Message />
                        <Notifications /> */}
					</div>

					<Profile>
						<div style={{ position: "relative" }}>
							<div>
								<h3
									style={{ textTransform: "capitalize", cursor: "pointer" }}
									onClick={showDropdown}
								>
									{adminName}
									<Down style={{ margin: "0 10px" }} />
								</h3>
								<Dp>
									<img src={avatar} alt="dp" />
								</Dp>
							</div>
							<Dropdown dropdown={dropdown}>
								<a href="/admin/profile">My Profile</a>
								<Button onClick={() => logout()}>
									<a href="/" style={{ marginBottom: "0" }}>
										Logout
									</a>
								</Button>
							</Dropdown>
						</div>
					</Profile>
				</Right>
			</Topbar>
			<DashboardSidebar isOpen={isOpen} toggle={toggle} />
		</>
	);
};

export default DashboardTopbar;
