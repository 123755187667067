import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import bg from "../../assets/tankbg.png";
import axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

const Bg = styled.div`
	background-image: url(${bg});
	background-size: 100% 100%;
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 500px;
	}
`;

const Group = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;
	padding: 4rem 3rem 10px 3rem;
	position: relative;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		padding: 4rem 1rem 10px 1rem;
	}

	h2 {
		font-weight: 700;
		font-size: 22px;
		margin-bottom: 10px;
		letter-spacing: 5px;
	}

	p {
		text-align: center;
		font-size: 13px;
		margin-bottom: 30px;
		color: #707070;
	}
`;
const HomeBox = styled.div`
	width: 100% !important;
	text-align: end;
	position: absolute;
	top: 1%;
	right: 1%;
	a {
		color: #707070;
	}
`;
const Info = styled.section`
	text-align: center;
`;

const Form = styled.section`
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	input {
		border: none;
		outline: none;
		border-bottom: 1px solid #ccc;
		padding: 8px 20px;
		width: 100%;
		font-size: 15px;
		margin-bottom: 15px;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 50px;
	}
`;

const RemFor = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
	width: 100%;

	div {
		display: flex;
		align-items: center;
		padding: 0;
		font-size: 13px;
		height: 20px;

		input {
			width: 15px;
			height: 15px;
			margin: 0 10px 0 0;
		}

		p {
			margin: 0;
		}
	}

	a {
		font-size: 13px;
		color: #000;
	}
`;

const Btns = styled.div`
	display: flex;
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 12px;
	color: #fff;
	width: 120px;
	font-size: 13px;
	border-radius: 3px;
`;

const Login = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 12px;
	color: #fff;
	width: 120px;
	font-size: 13px;
	border-radius: 3px;
	outline: none;
	border: none;
	cursor: pointer;
`;

const Powered = styled.section`
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 10px;
	left: 10px;
	right: 10px;

	p {
		margin: 0;
		color: #43425d;
		font-size: 12px;
	}
`;
const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const AdminLogin = () => {
	let history = useHistory();
	const [loggedIn, setLoggedIn] = useState(false);

	const [loading, setLoading] = useState(false);

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	// const [error, setError] = useState(false);
	useEffect(() => {
		const getToken = localStorage.getItem("toast");
		console.log(getToken);
		if (getToken) {
			setLoggedIn(true);
		}
	}, [loggedIn]);

	useEffect(() => {
		console.log(loggedIn);
		if (loggedIn) {
			toast.error("You're not logged in, please do.");
		}
		return () => {
			localStorage.removeItem("toast");
		};
	}, [loggedIn]);
	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		axios
			.post("admin/login", { username, password })
			.then((response) => {
				localStorage.setItem("admin-token", response.data.token);

				localStorage.setItem("admin", JSON.stringify(response.data.user));
				localStorage.setItem("role", JSON.stringify(response.data.user?.role));
				// console.log(response.data.token);
				// console.log(response.data.user);
				// console.log(response.data.user.role);

				if (response.data.error) {
					toast.error("Email or Password Incorrect");
					setLoading(false);
				} else if (response.data.token) {
					history.push("/admin");
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
				toast.error("Server error, try again later");
			});
	};

	// useEffect(() => {
	//     const user = localStorage.getItem('admin-token');

	//     if (user === 'undefined') {
	//         history.push('/admin-login')
	//     } else if (!user) {
	//         history.push('/admin-login')
	//     } else {
	//         history.push('/admin')
	//     }
	// }, [])

	return (
		<Container>
			<Bg />
			<Group>
				<HomeBox>
					<Link to="/">Back to Homepage</Link>
				</HomeBox>
				<div>
					<Info>
						<h2>Kephren</h2>
						<p>Welcome back! Please login to your account.</p>
					</Info>
					<Form>
						<form onSubmit={onSubmit}>
							<input
								type="text"
								placeholder="Username"
								name="username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								required
							/>
							<input
								type="password"
								placeholder="Password"
								name="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								style={{ marginBottom: "30px" }}
								required
							/>
							<RemFor>
								<div>
									<input type="checkbox" /> <p>Remember me</p>
								</div>
								<a href="/admin-forgot-password">Forgot Password</a>
							</RemFor>

							<Btns>
								{/* <Button to='/signup' style={{background: '#A1A1A1', border: '2px solid #707070', marginRight: '15px'}}>Register</Button> */}
								<Login type="submit" style={{ background: "#43425D" }}>
									Login
								</Login>
							</Btns>
						</form>
						<Load loading={loading}>
							<ReactLoading type={"spin"} color="#4D4F5C" />
						</Load>
					</Form>
				</div>

				<Powered>
					<p>Powered by Petrong Software Solutions</p>
					<p>Pacioli</p>
				</Powered>
			</Group>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default AdminLogin;
