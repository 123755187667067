import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import axios from "axios";
import ReactLoading from "react-loading";

// //svgs
// import { ReactComponent as Srch } from "../../assets/icons/search.svg";
// // import { ReactComponent as Arr } from '../assets/icons/downarrowbig.svg'
// import { ReactComponent as Prev } from "../../assets/icons/prev.svg";
// import { ReactComponent as Next } from "../../assets/icons/next.svg";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import SentDocTables from "../../components/AdminTables/SentDocTables";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	width: 100%;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}
`;

const HeadingSearch = styled.section`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 1rem 0 0 2rem;
	@media screen and (max-width: 1130px) {
		flex-direction: column;
	}

	h2 {
		font-weight: 200;

		@media screen and (max-width: 1130px) {
			margin-bottom: 15px;
		}
	}
`;

const Search = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 20px;
	width: 400px;
	background: #fff;

	@media screen and (max-width: 1130px) {
		width: 100%;
	}

	input {
		outline: none;
		border: none;
	}
`;

const Info = styled.div`
	
	background: #fff;
	min-height: 60vh;
	width: 90%;
	margin: 0 auto;
	@media screen and (max-width: 480px) {
		width: 100%;
		min-height: 40vh;
	}
`;

export const Space = styled.div`
	padding: 0.5rem 1.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	border-right: 1px solid #e9e9f0;
	@media only screen and (min-width: 769px) and (max-width: 1200px) {
		width: 25%;
	}
	@media screen and (max-width: 768px) {
		width: 25%;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
		height: 5vh;
		border-right: none;
	}
`;
export const Upload = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.4rem;
	width: 15%;
	background: #43425d;
	font-size: 0.8rem;
	font-weight: 550;
	margin-right: 2rem;
	margin-top: 1rem;
	height: 35px;
	border-radius: 3px;
	color: white;
	text-decoration: none;

	@media only screen and (min-width: 769px) and (max-width: 1200px) {
		font-size: 0.7rem;
		font-weight: 550;
	}
	@media screen and (max-width: 480px) {
		width: 35%;
		margin: 0.8rem auto 0;
	}
`;


const Details = styled.div`
	/* display: flex; */
	display: ${({ loading }) => (loading ? "none" : "flex")};
	flex-direction: column;

	width: 100%;
	@media only screen and (min-width: 769px) and (max-width: 1200px) {
		width: 75%;
	}
	@media screen and (max-width: 768px) {
		width: 75%;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
	}
	h3 {
		font-weight: 400;
	}
`;

const Table = styled.div`
	margin-top: 15px;

	@media screen and (max-width: 1200px) {
		overflow-x: scroll;
		width: 100%;
	}
`;

const Pages = styled.div`
	display: flex;
	justify-content: end;

	&:last-child {
		margin-right: 0;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const AdminSentDoc = () => {
	let history = useHistory();
	const [docs, setDocs] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [docsPerPage, setDocsPerPage] = useState(10);
	const [loading, setLoading] = useState(false);
	const [activePage, setActivePage] = useState(false);

	const fetchDocs = () => {
		const adminInfo = JSON.parse(localStorage.getItem("user"));
		const id = adminInfo?.id;
		setLoading(true);
		axios
			.get(`/admin/getall-sent-docs`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => {
				setLoading(false);
				setDocs(response.data.sent_documents);
				console.log(response);

				// localStorage.setItem('admin_sent_docs', response.data.sent_documents.length)
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				if (err) {
					history.push("/admin-login");
				}
			});
	};

	useEffect(() => {
		const user = localStorage.getItem("admin-token");

		if (user === "undefined" || !user) {
			history.push("/admin-login");
		}
		fetchDocs();
	}, []);

	//Get current posts
	const indexOfLastDoc = currentPage * docsPerPage;
	const indexOfFirstDoc = indexOfLastDoc - docsPerPage;
	const currentDocs = docs.slice(indexOfFirstDoc, indexOfLastDoc);

	const totalDocs = docs.length;
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalDocs / docsPerPage); i++) {
		pageNumbers.push(i);
	}

	//change page
	const paginate = (number) => {
		setCurrentPage(number);
		setActivePage(true);
	};

	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<HeadingSearch>
						<h2>Secured Document Exchange Portal</h2>

						{/* <Search>
                            <Srch style={{marginRight: '15px'}} /> <input type="text" placeholder='Search Title ...' />
                        </Search> */}
					</HeadingSearch>

					<Info>
						<Space>
						<h3>Sent Documents</h3>
							<Upload to="/admin/upload">UPLOAD DOCUMENT</Upload>
						</Space>

						<Details>
							

							{docs.length === 0 ? (
								<p style={{ textAlign: "center" }}>No Sent document</p>
							) : (
								<Table>
									{" "}
									<SentDocTables docs={currentDocs} />{" "}
								</Table>
							)}

							<Pages>
								{pageNumbers.map((number) => (
									<div
										key={number}
										onClick={() => paginate(number)}
										activePage={activePage}
									>
										{number}
									</div>
								))}
							</Pages>
						</Details>
					</Info>
				</Board>
			</Contents>
			<Load loading={loading}>
				<ReactLoading type={"spin"} color="#4D4F5C" />
			</Load>
		</Container>
	);
};

export default AdminSentDoc;
