import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ReactComponent as Arrow } from "../../assets/arrow-right.svg";
import bg from "../../assets/kephren.jpg";

//styles
const Container = styled.section`
	display: flex;
	margin-top: 105px;
	padding: 0rem 0 2rem 0;
	position: relative;
	z-index: 2;

	@media screen and (max-width: 1200px) {
		padding: 2rem 0 2rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 2rem 0 2rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 2rem 0 2rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 2rem 5rem;
		flex-direction: column;
	}
	@media screen and (max-width: 600px) {
		padding: 2rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 2rem 1rem;
	}

	@media screen and (max-width: 480px) {
		padding: 2rem  0;
	}
`;
export const Texts = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 47%;
	padding: 0 2rem 0 1rem;
	position: relative;
	z-index: 2;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
	@media screen and (max-width: 480px) {
		width: 95%;
		padding: 0;
		margin: 0 auto;
		text-align: justify;
		p{
			text-align: justify !important;
		}
	}

	h2 {
		font-weight: 200;
		font-size: 30px;
		line-height: 35px;
		position: relative;
		z-index: 2;
		margin-bottom: 0;
	}

	h1 {
		font-size: 40px;
		font-weight: bold;
		line-height: 45px;
		position: relative;
		margin-top: 0;
		margin-bottom: 25px;
		position: relative;
		z-index: 2;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			height: 3px;
			background: #f92524;
			width: 55px;
		}
	}

	p {
		margin-bottom: 25px;
		font-size: 1rem;
		color: #7a839e;
		text-align: start;
	}
`;


const Circle = styled.div`
	width: 400px;
	height: 400px;
	border-radius: 50%;
	background: #f4f5fa;
	position: absolute;
	top: -280px;
	left: -250px;
	z-index: 1;
	@media only screen and (max-width: 768px){
		display: none;
	}
`;

const Button = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 5px 8px 10px;
	background: #142251;
	font-size: 13px;
	width: 200px;
	border-radius: 3px;
`;

export const Bg = styled.div`
	width: 60%;
	height: 600px;
	display: flex;

	position: relative;
	z-index: 2;
	img {
		position: relative;
		z-index: 2;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 450px;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		height: 300px;
	}

	img {
		height: 100%;
		width: 80%;
		
		position: absolute;

		@media screen and (max-width: 768px) {
			position: initial;
		}
		@media only screen and (max-width: 480px) {
			margin-bottom: 0;
			
				height: 90%;
			
		}
	}
`;

const Home = () => {
	return (
		<Container id="home">
			
			<Bg>
				<img src={bg} alt="bg" />
			</Bg>
			<Texts>
				{/* <Circle /> */}
				{/* <h2>We track for a transparent</h2> */}
				<h1>Kephren</h1>
				<p>
					One of Nigeria’s leading Crude oil export monitoring and tracking
					platforms. We have developed the capacity to monitor and track VLCCs
					and other crude tankers that sale into Nigeria’s export terminals and
					report shipments of crude oil loaded for exports.
				</p>
				<Button to="/signup">
					CREATE AN ACCOUNT <Arrow style={{ marginLeft: "20px" }} />
				</Button>
			</Texts>

		</Container>
	);
};

export default Home;
