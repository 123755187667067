import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import axios from "axios";
import format from "../../assets/format.png";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//svgs
import { ReactComponent as Upload } from "../../assets/file.svg";

//components
import Sidebar from "../../components/DashboardSidebars/Client";
import Topbar from "../../components/DashboardTopbars/Client";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 2rem 5rem;
	height: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}

	h2 {
		font-weight: 200;
		padding: 1.5rem 0;

		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	background: #fff;
`;

const Space = styled.div`
	padding: 0.5rem;
	width: 200px;
	border-right: 1px solid #e9e9f0;

	@media screen and (max-width: 750px) {
		display: none;
	}
`;
const Back = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 2rem;
	width: 700px;
	overflow: hidden;
	position: relative;

	@media screen and (max-width: 950px) {
		width: auto;
	}

	@media screen and (max-width: 950px) {
		width: 100%;
	}

	h3 {
		font-weight: 400;
	}
`;

const Groups = styled.form`
	display: flex;
	flex-direction: column;
	padding: 4rem 0 4rem 0;
	position: relative;
	overflow: hidden;

	input {
		border: 1px solid #e8e9ec;
		outline: none;
		padding: 8px 20px;
		color: #000;
		font-size: 14px;
		margin-bottom: 15px;
	}

	div {
		display: flex;
		justify-content: space-between;

		@media screen and (max-width: 850px) {
			flex-direction: column;
		}

		input {
			/* width: 48%; */

			@media screen and (max-width: 850px) {
				width: 100%;
			}
		}
	}
`;

const Description = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid #e8e9ec;

	div {
		padding: 10px;
		border-bottom: 1px solid #e8e9ec;
	}

	textarea {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		height: 100px;
		border: none;
		padding: 8px 20px;
		outline: none;
	}
`;

const Choose = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #f0f2f8;
	width: 300px;
	padding: 1rem;
	margin-top: 15px;

	@media screen and (max-width: 850px) {
		width: 100%;
	}

	svg {
		margin-bottom: 15px;
	}

	p {
		margin-bottom: 15px;
		text-align: center;
	}

	input {
		background: #3b86ff;
		color: #fff;
		cursor: pointer;
		border-radius: 3px;
		border: none;
		outline: none;
		padding: 8px;
		margin: 0;
		width: 100%;

		@media screen and (max-width: 850px) {
			width: 150px;
		}
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	width: 150px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	position: absolute;
	right: 0;
	bottom: 0;
	border: none;
	outline: none;
	color: #fff;
	cursor: pointer;
`;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 50%;
	left: 50%;
`;

const CustomerUploadDoc = () => {
	let history = useHistory();

	const [loading, setLoading] = useState(false);

	const [admin_receiver, setAdmin_receiver] = useState("");
	const [title, setTitle] = useState("");
	const [more_info, setMore_info] = useState("");
	const [other_info, setOther_info] = useState("");
	const [description, setDescription] = useState("");
	const [filenames, setFilenames] = useState([]);


	const userInfo = JSON.parse(localStorage.getItem("user"));
	const onSubmit = (e) => {
		e.preventDefault();

		let form = document.getElementById("formUploadForm");
		let formData = new FormData(form);
		console.log(formData);
		setLoading(true);

		axios
			.post("user/upload-doc", formData, {
				headers: {
					// 'Content-Type':'multipart/form-data',
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
			.then((response) => {
				console.log(response.data);
				if (response.data.status) {
					toast.success("Document Uploaded Successfully");
					history.push("/dashboard/upload/success");
				} else if (!response.data.status) {
					console.log(response.data);
					toast.error("Failed to Upload Document, Try Again");
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
				if (error) {
					history.push("/login");
				}
			});
	};

	useEffect(() => {
		const user = localStorage.getItem("token");

		if (user === "undefined" || !user) {
			history.push("/login");
		}
	}, []);

	return (
		<Container>
			<Sidebar />
			<Contents>
				<Topbar />

				<Board>
					<h2>Secured Document Exchange Portal</h2>

					<Info>
						<Space>
							<Back to="/dashboard">BACK</Back>
						</Space>

						<Details>
							<h3>Upload Documents</h3>

							<Groups
								onSubmit={onSubmit}
								id="formUploadForm"
								enctype="multipart/form-data"
							>
								{/* <input type='text' placeholder='CHOOSE RECIPIENT' name='admin_receiver' value={admin_receiver } onChange={(e) => setAdmin_receiver(e.target.value)} required /> */}
								<input
									type="text"
									placeholder="TITLE"
									name="title"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									required
								/>
								{/* <div>
                                    <input type='text' placeholder='MORE INFO' name='more_info' value={more_info } onChange={(e) => setMore_info(e.target.value)} required />
                                    <input type='text' placeholder='OTHER INFO' name='other_info' value={other_info } onChange={(e) => setOther_info(e.target.value)} required />
                                </div> */}
								<Description>
									{/* <div>
                                        <img src={format} alt='format' />
                                    </div> */}
									<textarea
										type="text"
										placeholder="DESCRIPTION"
										name="description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										required
									/>
								</Description>
								<Choose>
									<Upload />
									{/* <p>Drag and drop a file here or </p> */}
									<input
										type="file"
										name="filenames[]"
										multiple
										required
										value={filenames}
										onChange={(e) => setFilenames(e.target.value)}
									/>
								</Choose>
								<input type="hidden" name="user_id" value={userInfo?.id} />
								<Button type="submit">Send</Button>
							</Groups>
							<Load loading={loading}>
								<ReactLoading type={"bars"} color="#4D4F5C" />
								<h5>Upoading...</h5>
							</Load>
						</Details>
					</Info>
				</Board>
			</Contents>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default CustomerUploadDoc;
