import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import logo from "../../assets/kephren-white.png";

import { FaTimes } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";

//svgs
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Received } from "../../assets/icons/received.svg";
import { ReactComponent as Sent } from "../../assets/icons/sent.svg";
import { ReactComponent as Profile } from "../../assets/icons/settings.svg";
import { ReactComponent as Help } from "../../assets/icons/help.svg";

//styles
const Sidebar = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100vh - calc(100vh - 100%));

	background: #4d4f5c;
	width: 200px;
	z-index: 100;
	position: fixed;
	left: 0;
	top: 0;

	@media screen and (max-width: 600px) {
		display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
		left: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
		transition: 0.5s ease-in-out;
	}

	div {
		display: flex;
		align-items: center;
		background: #43425d;
		padding: 1rem;
		color: #fff;
		height: 60px;
		font-size: 14px;
		font-weight: 700;
		position: relative;
	}
`;

const Close = styled(FaTimes)`
	display: none;

	@media screen and (max-width: 600px) {
		display: flex;
		width: 25px;
		height: 25px;
		position: absolute;
		right: 1rem;
	}
`;

const Tabs = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 3rem 0 1rem 1rem;
	height: 100%;

	div {
		display: flex;
		flex-direction: column;
		align-items: start;
		background: transparent;
		padding: 0;
		height: auto;
	}
`;

const btnstyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 15px;
	border-radius: 3px;
	cursor: pointer;
	font-size: 12px;
	color: #fff;
	background: transparent;
	margin-bottom: 2rem;
`;

const TabsBtn = styled(Link)`
	${btnstyle};
	justify-content: start;
	padding: 0;
	outline: none;
	border: none;
	font-weight: 200;
	font-size: 14px;
	width: 100%;

	svg {
		margin-right: 10px;
	}
`;

const DashboardSidebar = ({ isOpen, toggle }) => {
	const logOut = () => {
		localStorage.removeItem("token");
	};
	return (
		<Sidebar isOpen={isOpen}>
			<div>
				<Link to="/">
					<img src={logo} alt="logo" style={{ width: "100px" }} />
				</Link>
				<Close onClick={toggle} />
			</div>

			<Tabs>
				<div>
					<TabsBtn to="/dashboard" onClick={toggle}>
						<Dashboard /> Dashboard
					</TabsBtn>

					<TabsBtn to="/dashboard/received" onClick={toggle}>
						<Received />
						Received
					</TabsBtn>

					<TabsBtn to="/dashboard/sent" onClick={toggle}>
						<Sent />
						Sent
					</TabsBtn>
				</div>

				<div>
					<TabsBtn to="/dashboard/profile" onClick={toggle}>
						<Profile />
						My Profile
					</TabsBtn>

					<TabsBtn to="/dashboard/help" onClick={toggle}>
						<Help />
						Help
					</TabsBtn>
					<TabsBtn to="/" onClick={logOut}>
						<FiLogOut />
						Logout
					</TabsBtn>
				</div>
			</Tabs>
		</Sidebar>
	);
};

export default DashboardSidebar;
