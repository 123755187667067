import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import bg from "../assets/tankbg.png";
import logo from "../assets/logo.png";
import axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		height: auto;
	}
`;

const Bg = styled.div`
	background-image: url(${bg});
	background-size: 100% 100%;
	width: 50%;

	@media screen and (max-width: 768px) {
		width: 100%;
		height: 500px;
	}
`;

const Group = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;
	/* padding: ; */
	position: relative;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	@media screen and (max-width: 768px) {
		padding: 4rem 1rem 10px 1rem;
	}

	div {
		width: 70%;

		@media screen and (max-width: 600px) {
			width: 80%;
		}
	}

	h2 {
		font-weight: 700;
		font-size: 22px;
		margin-bottom: 10px;
		letter-spacing: 5px;
	}

	p {
		text-align: center;
		font-size: 13px;
		margin-bottom: 20px;
		color: #707070;
	}
`;
const HomeBox = styled.div`
	width: 100% !important;
	text-align: end;
	position: absolute;
	top: 1%;
	right: 1%;
	a {
		color: #707070;
	}
`;
const Info = styled.section`
	text-align: center;
`;

const Form = styled.section`
	position: relative;
	width: 90%;

	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	input {
		border: none;
		outline: none;
		border-bottom: 1px solid #ccc;
		padding: 8px 20px;
		width: 100%;
		font-size: 13px;
		margin-bottom: 10px;
	}

	@media screen and (max-width: 768px) {
		margin-bottom: 50px;
	}
`;

const Name = styled.section`
	display: flex;
	justify-content: space-between;
	width: 100%;

	@media screen and (max-width: 400px) {
		flex-direction: column;
	}

	input {
		width: 45%;

		@media screen and (max-width: 400px) {
			width: 100%;
		}
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.6rem 0;
	color: #fff;
	width: 60%;
	font-size: 13px;
	border-radius: 3px;
	background: #43425d;
	margin-top: 20px;
	outline: none;
	border: none;
	cursor: pointer;
`;

const Powered = styled.section`
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 10px;
	left: 10px;
	right: 10px;

	p {
		margin: 0;
		color: #43425d;
		font-size: 12px;
	}
`;

const Error = styled.h5`
	color: red;
	font-weight: 400;
	font-size: 12px;
	display: ${({ error }) => (error ? "flex" : "none")};
	width: 100%;
	text-align: left;
`;

const Login = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 12px;
	color: #fff;
	width: 150px;
	font-size: 13px;
	border-radius: 3px;
	background: #43425d;
	margin-top: 20px;
	outline: none;
	border: none;
	cursor: pointer;
`;

const Btns = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
`;
const LoginDiv = styled.div`
	width: 100% !important;
	text-align: start;
`;
const Loginlink = styled(Link)`
	display: block;
	text-align: start;
	margin: 0;
	p {
		font-size: 1rem;
		text-align: start;
	}
`;
const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const SignUp = () => {
	let history = useHistory();
	const [loading, setLoading] = useState(false);

	const [user, setUser] = useState({
		first_name: "",
		last_name: "",
		company: "",
		email: "",
		password: "",
		password_confirmation: "",
	});
	const [error, setError] = useState(false);

	const onChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const onSubmit = (e) => {
		e.preventDefault();

		// if (user.password !== user.password_confirmation) {
		//     setError(true)
		//     setLoading(false)
		// } else {
		//     setError(false)
		// }

		if (user.password !== user.password_confirmation) {
			setLoading(false);
			setError(true);
		} else {
			setLoading(true);
			axios
				.post("user/signup", user)
				.then((response) => {
					console.log(response.data);

					if (response.data.status) {
						history.push("/success");
					} else if (!response.data.status) {
						toast.error("Failed, Try Again");
						setLoading(false);
					}

					setUser(response.data);
				})
				.catch((error) => {
					// console.log(error.response)
					if (error.response.status === 422) {
						toast.error("User already exist");
						setLoading(false);
					} else {
						toast.error("Server error, try again later");
					}
				});
		}
	};

	return (
		<Container>
			<Bg />
			<Group>
				<HomeBox>
					<Link to="/">Back to Homepage</Link>
				</HomeBox>
				<div>
					<Info>
						{/* <h2>Kephren</h2> */}
						<Link to="/">
							<img
								src={logo}
								alt="logo"
								style={{ width: "150px", marginBottom: "10px" }}
							/>
						</Link>
						<p>Please complete to add new user</p>
					</Info>
					<Form>
						<form onSubmit={onSubmit}>
							<Name>
								<input
									type="text"
									placeholder="First Name"
									name="first_name"
									value={user.first_name}
									onChange={onChange}
									required
								/>
								<input
									type="text"
									placeholder="Last Name"
									name="last_name"
									value={user.last_name}
									onChange={onChange}
									required
								/>
							</Name>
							<input
								type="text"
								placeholder="Company"
								name="company"
								value={user.company}
								onChange={onChange}
								required
							/>
							<input
								type="email"
								placeholder="Email"
								name="email"
								value={user.email}
								onChange={onChange}
								required
							/>
							<input
								type="password"
								placeholder="Password"
								name="password"
								value={user.password}
								onChange={onChange}
								required
							/>
							<input
								type="password"
								placeholder="Confirm Password"
								name="password_confirmation"
								value={user.password_confirmation}
								onChange={onChange}
								required
							/>
							<Error error={error}>Password does not match</Error>
							<LoginDiv>
								<Loginlink to="/login">
									<p>Already registered? Login</p>
								</Loginlink>
							</LoginDiv>
							<Btns>
								{/* <Login
									to="/login"
									style={{
										background: "#A1A1A1",
										border: "2px solid #707070",
										marginRight: "15px",
									}}
								>
									Login
								</Login> */}
								<Button type="submit">Create Account</Button>
							</Btns>
						</form>

						<Load loading={loading}>
							<ReactLoading type={"spin"} color="#4D4F5C" />
						</Load>
					</Form>
				</div>

				<Powered>
					<p>Powered by Petrong Software Solutions</p>
					<p>Pacioli</p>
				</Powered>
			</Group>

			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default SignUp;
