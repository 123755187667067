import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import bg from "../../assets/footerbg.png";
import logo from "../../assets/logo2.png";
import { ReactComponent as Fb } from "../../assets/fb.svg";
import { ReactComponent as Gm } from "../../assets/gm.svg";
import { ReactComponent as Tw } from "../../assets/tw.svg";
import { ReactComponent as Ig } from "../../assets/ig.svg";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 6rem;
	height: 500px;
	background-image: url(${bg});
	background-size: 100% 100%;
	padding: 0 10rem;

	@media screen and (max-width: 1200px) {
		padding: 0 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 0 7rem;
	}
	@media screen and (max-width: 920px) {
		padding: 0 6rem;
		height: 600px;
	}
	@media screen and (max-width: 768px) {
		padding: 0 5rem;
		background-size: 140% 100%;
	}
	@media screen and (max-width: 600px) {
		padding: 0 4rem;
		background-size: 170% 100%;
		height: 650px;
	}

	@media screen and (max-width: 500px) {
		padding: 0 3rem;
		background-size: 300% 100%;
		height: 700px;
	}

	@media screen and (max-width: 450px) {
		padding: 0 2rem;
		height: 750px;
	}

	@media screen and (max-width: 390px) {
		height: 850px;
	}

	@media screen and (max-width: 350px) {
		height: 950px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 1.5rem;

	@media screen and (max-width: 920px) {
		flex-direction: column;
	}
`;

const Left = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	width: 40%;
	color: #fff;

	@media screen and (max-width: 1200px) {
		width: 45%;
	}

	@media screen and (max-width: 920px) {
		width: 100%;
	}

	img {
		height: 60px;
		width: 140px;
		margin-bottom: 40px;
	}

	p {
		margin-bottom: 10px;
		font-size: 13px;
		font-weight: 400;
		color: #bdbdbd;
	}
	ul {
		list-style-type: none;
		li {
			margin-bottom: 10px;
			font-size: 13px;
			font-weight: 400;
			color: #bdbdbd;
		}
	}
	svg {
		margin-right: 15px;
	}
`;

const Sm = styled.div`
	display: flex;
`;

export const Right = styled.div`
	display: flex;
	
	justify-content: space-around;
	width: 55%;
	color: #fff;

	@media screen and (max-width: 1200px) {
		width: 50%;
	}

	@media screen and (max-width: 920px) {
		width: 100%;
		margin-top: 40px;
	}

	h3 {
		position: relative;
		font-size: 25px;
		margin-bottom: 20px;
		color: #fff;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			height: 3px;
			background: #fff;
			width: 55px;
		}
	}
`;

export const RightChild = styled.div`
width: 45%;
	display: flex;
justify-content: space-between;


	@media screen and (max-width: 920px) {
		justify-content: space-between;
	}

	@media screen and (max-width: 390px) {
		flex-direction: column;
	}

	p {
		margin-bottom: 10px;
		font-size: 1rem;
		margin-right: 55px;
		font-weight: 400;
		color: #fff;
		a {
			color: #fff;
		}
		@media screen and (max-width: 1050px) {
			margin-right: 20px;
		}
	}

	h5 {
		margin: 15px 0;
		color: #f5f5f5;
	}
`;
export const RightChildA = styled.div`
	
width: 40%;
	@media screen and (max-width: 920px) {
		justify-content: space-between;
	}

	@media screen and (max-width: 390px) {
		flex-direction: column;
	}
a {
	text-decoration: none;
}
	p {
		margin-bottom: 10px;
		font-size: 1rem;
		margin-right: 55px;
		font-weight: 400;
		color: #fff;
		a {
			color: #fff;
		}
		@media screen and (max-width: 1050px) {
			margin-right: 20px;
		}
	}

	h5 {
		margin: 15px 0;
		color: #f5f5f5;
	}
`;

const Cr = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1.5rem 0;
	border-top: 1px solid #757575;
	font-size: 1rem;
	font-weight: 400;
	color: #fff;
`;

const Footer = () => {
	return (
		<Container id="contact">
			<Wrapper>
				<Left>
					<img src={logo} alt="logo" />

					{/* <p>The Company is structured into 3 main functional departments.</p> */}

					{/* <ul>
						<li>Oil and Gas Inspection and Documentation (Training)</li>
						<li>Engineering and Information Systems (Operations)</li>
						<li>Marine Services (Environment and pollution control)</li>
					</ul> */}

					<Sm>
						<Link to="/">
							{" "}
							<Fb />{" "}
						</Link>
						<Link to="/">
							{" "}
							<Gm />{" "}
						</Link>
						<Link to="/">
							{" "}
							<Tw />{" "}
						</Link>
						<Link to="/">
							{" "}
							<Ig />{" "}
						</Link>
					</Sm>
				</Left>

				<Right>
					

					<RightChild>
						<div>
							<h3>Address</h3>
							<p>38A, Danube Street, Maitama District, Abuja</p>
						
						</div>

						
					</RightChild>
					<RightChildA>
							<h3>Contact Info</h3>
							<p>
								<a href="mailto:info@kephrenng.com">info@kephrenng.com</a>
							</p>
							<p>
								
								<a href="tel:+234-803-690-7305">+234-803-690-7305</a>
							</p>
						</RightChildA>
				</Right>
			</Wrapper>
			<Cr>Copyright © 2022 Kephren. All rights reserved.</Cr>
		</Container>
	);
};

export default Footer;
