import React, {useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

//components
import Topbar from '../../components/DashboardTopbars/Client'

const Container = styled.section`
    display: flex;
    height: 100vh;
    overflow: hidden;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 200px;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 600px) {
        padding: 0;
    }
`

const Board = styled.div`
    background: #F0F0F7;
    height: 100%;
    padding: 2rem 5rem 8rem 5rem; 

    @media screen and (max-width: 600px) {
        padding: 2rem;
    }
    
    h2 {
        margin-bottom: 20px;
        font-weight: 200;

        @media screen and (max-width: 350px) {
            font-size: 18px;
        }
    }
`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 2rem 2rem;
    height: auto;

    p, a {
        font-size: 13px;
    }

    div {
        margin-top: 3rem;
        width: 500px;

        @media screen and (max-width: 1000px) {
        width: 100%;
        }

        a {
            color: #000;
        }
    }
`

const Slide = styled.section`
    width: 100%;
    height: 200px;
    background: #43425D;
    margin-top: 15px;
`

const CustomerHelp = () => {
    let history = useHistory();  

    useEffect(() => {
        const user = localStorage.getItem('token');

        if (user === 'undefined' || !user) {
            history.push('/login')
        } 
    }, [])

    return (
        <Container>
            <Contents>
                <Topbar />
                
                <Board>
                    <h2>Help</h2>

                    <Info>
                        <p>For any questions or assistance, ask the super administrator for help.</p>

                        <div>
                            <p>This application is powered by Petrong Software Solutions. All Rights reserved. Copyright 2021.</p>
                            <a href='https://petrongsoftware.com'>www.petrongsoftware.com</a>
                            <Slide></Slide>
                        </div>
                    </Info>
                </Board>
            </Contents>
        </Container>
    )
}

export default CustomerHelp
