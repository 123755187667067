import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import axios from "axios";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//components
import Sidebar from "../../components/DashboardSidebars/Admin";
import Topbar from "../../components/DashboardTopbars/Admin";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 2rem 5rem;
	height: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}

	h2 {
		font-weight: 200;
		padding: 1.5rem 0;

		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	background: #fff;
`;

const Space = styled.div`
	padding: 0.5rem;
	width: 200px;
	border-right: 1px solid #e9e9f0;

	@media screen and (max-width: 750px) {
		display: none;
	}
`;
const Back = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 6rem 20px 2rem;
	overflow: hidden;
	width: 100%;

	@media screen and (max-width: 1100px) {
		padding: 20px 2rem;
	}

	@media screen and (max-width: 950px) {
		width: 100%;
	}

	@media screen and (max-width: 350px) {
		padding: 20px 1rem;
	}

	h3 {
		font-weight: 400;
	}
`;

const Groups = styled.form`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 4rem 1rem;
	margin-top: 3rem;
	width: 100%;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 360px) {
		padding: 0 0 4rem 0;
	}

	input {
		border: 1px solid #bbb;
		outline: none;
		padding: 8px 20px;
		color: #000;
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 15px;
		width: 100%;

		&::placeholder {
			color: #000;
		}
	}
`;

const Rank = styled.select`
	border: 1px solid #bbb;
	width: 200px;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 15px;
	padding: 8px 20px;
	outline: none;

	@media screen and (max-width: 360px) {
		width: 150px;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	width: 150px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	outline: none;
	border: none;
	color: #fff;

	@media screen and (max-width: 350px) {
		font-size: 12px;
		padding: 8px;
	}
`;

const Error = styled.h5`
	color: red;
	font-weight: 400;
	font-size: 12px;
	display: ${({ error }) => (error ? "flex" : "none")};
`;

const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 50%;
	left: 50%;
`;

const CreateAdmin = () => {
	let history = useHistory();
	const [loading, setLoading] = useState(false);
	const [editing, setediting] = useState(false);
	const [admin, setAdmin] = useState({
		// adminid: '',
		fullname: "",
		username: "",
		phone_number: "",
		email: "",
		password: "",
		password_confirmation: "",
		role: "",
	});
	const [error, setError] = useState(false);
	const id = useParams();
	const adminID = id.id;
	const onChange = (e) => {
		setAdmin({ ...admin, [e.target.name]: e.target.value });
	};
	const onSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		if (editing === true) {
			updateData();
		} else {
			if (admin.password !== admin.password_confirmation) {
				setError(true);
				setLoading(false);
			} else {
				setError(false);
			}
			console.log(admin);
			axios
				.post("admin/create", admin)
				.then((response) => {
					console.log(response.data);

					if (response.data.status) {
						toast.success("Admin Created Successfully");
						window.location.reload();
					} else if (!response.data.status) {
						toast.error("Failed, Try Again");
						setLoading(false);
					}

					setAdmin(response.data);
				})
				.catch((error) => {
					console.log(error);
					if (error) {
						history.push("/admin-login");
					}
				});
		}
	};

	useEffect(() => {
		const user = localStorage.getItem("admin-token");
		console.log(editing);
		const editStatus = localStorage.getItem("Edit");
		console.log(editStatus);
		if (editStatus === "yes") {
			editData();
		}
		if (user === "undefined" || !user) {
			history.push("/admin-login");
		}
		return () => {
			localStorage.removeItem("Edit");
		};
	}, []);
	const editData = () => {
		setediting(true);
		console.log(editing);
		axios
			.get(`admin/getone/${adminID}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})

			.then((response) => {
				const adminInfo = response.data;
				setAdmin(adminInfo);
				console.log(response);
				console.log(admin);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const updateData = () => {
		console.log(admin);
		axios
			.put(`admin/edit/${adminID}`, admin, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => {
				console.log(response);

				if (response.status === 200) {
					toast.success("Admin update Successfully");
					// window.location.reload();
				} else if (response.status !== 200) {
					toast.error("Failed, Try Again");
					setLoading(false);
				}
				setAdmin(response.data);

				setTimeout(() => {
					history.push("/admin/administrators");
				}, 500);
			})
			.catch((error) => {
				console.log(error);
				if (error) {
					history.push("/admin-login");
				}
			});
	};
	return (
		<Container>
			<Sidebar />
			<Contents>
				<Topbar />

				<Board>
					<h2>Administrators</h2>

					<Info>
						<Space>
							<Back to="/admin/administrators">BACK</Back>
						</Space>

						<Details>
							<h3>New Administrator</h3>

							<Groups onSubmit={onSubmit}>
								{/* <input
									type="text"
									placeholder="ADMIN ID"
									name="adminid"
									value={admin.adminid}
									onChange={onChange}
									required
								/> */}
								<input
									type="text"
									placeholder="FULL NAME"
									name="fullname"
									value={admin?.fullname}
									onChange={onChange}
									required
								/>
								<input
									type="text"
									placeholder="USERNAME"
									name="username"
									value={admin?.username}
									onChange={onChange}
									required
								/>
								<input
									type="text"
									placeholder="PHONE NO"
									name="phone_number"
									value={admin?.phone_number}
									onChange={onChange}
									required
								/>
								<input
									type="text"
									placeholder="EMAIL"
									name="email"
									value={admin?.email}
									onChange={onChange}
									required
								/>

								<input
									type="password"
									placeholder="PASSWORD"
									name="password"
									value={admin?.password}
									onChange={onChange}
									required
								/>
								<input
									type="password"
									placeholder="PASSWORD CONFIRMATION"
									name="password_confirmation"
									value={admin?.password_confirmation}
									onChange={onChange}
									required
								/>
								<Error error={error}>Password does not match</Error>
								<Rank name="role" onChange={onChange} value={admin?.role}>
									<option value="" disabled selected hidden>
										ROLE
									</option>
									<option value="Super Admin">SUPER ADMIN</option>
									<option value="Admin">ADMIN</option>
								</Rank>
								<Button type="submit">
									{editing === true ? "UPDATE" : "CREATE"}
								</Button>
							</Groups>
							<Load loading={loading}>
								<ReactLoading type={"spin"} color="#4D4F5C" />
							</Load>
						</Details>
					</Info>
				</Board>
			</Contents>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default CreateAdmin;
