import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ReactComponent as Arrow } from "../../assets/newsarrow.svg";
import image1 from "../../assets/news4.png";
// import image2 from "../../assets/news2.png";
import { ReactComponent as Time } from "../../assets/time.svg";
import { ReactComponent as Dots } from "../../assets/greydot-news.svg";
import { ReactComponent as Red } from "../../assets/reddot-news.svg";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { A11y, Autoplay, Navigation } from "swiper";
//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 4rem 10rem;
	position: relative;

	@media screen and (max-width: 1200px) {
		padding: 6rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 6rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 6rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 6rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 6rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 6rem 3rem;
	}

	@media screen and (max-width: 480px) {
		padding: 2rem 0.5rem;
	}

	h2 {
		position: relative;
		margin-bottom: 25px;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			height: 3px;
			background: #f92524;
			width: 55px;
		}
	}

	p {
		color: #7a839e;
		font-size: 1rem;
		margin-bottom: 15px;
	

		@media screen and (max-width: 1160px) {
			width: 100%;
		}
	}
`;

const Wrapper = styled.section`
	display: flex;
	/* padding: 6rem 0 0 10rem; */
	margin-top: 2rem;
	height: auto;
	
	.swiper {
		height: 45vh;
		z-index: 5;
		width: 100%;
		padding: 0 5rem;
		.swiper-slide {
			overflow: visible;
			background-color: rgba(45, 45, 45, 0.2);
			padding: 1rem;
			height: 65%;
			a {
				color: black;
				text-decoration: none;
				h6 {
					font-size: 1.3rem;
					font-weight: bolder;
					color: black;
					margin: 1rem 0.5rem 1.5rem;
				}
				p {
					font-size: 1rem;
					font-weight: bold;
					color: black;
				}
			}
		}
		.swiper-button-prev {
			top: 89%;
			left: 42%;
			width: 4%;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			height: 15%;
			background-color: rgb(20,34,81);
			border-radius: 50%;
			&:after {
				font-size: 2rem;
				color: white;
			}
		}
		.swiper-button-next {
			top: 89%;
			right: 45%;
			width: 4%;
			height: 15%;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			background-color: rgb(20,34,81);
			border-radius: 50%;
			&:after {
				font-size: 2rem;
				color: white;
			}
		}
		// .swiper-slide-active {
		// 	background-image: url(${image1});
		// }

		@media only screen and (max-width: 768px){
			.swiper-button-prev {
				width: 8%;
				left: 40%;
			}
			.swiper-button-next {
				width: 8%;
				right: 40%;
			}
		}
		@media only screen and (max-width: 480px) {
			height: 45vh;
			width: 100%;
			
				padding: 0;
			
			.swiper-slide {
				height: 70%;
				padding: 0;
				a {
					h6 {
						font-size: 1.5rem;
						margin: 2rem 0.5rem 1rem;
					}
				}
			}
			.swiper-button-prev{
				width: 12%;
				left:35%;
			}
			.swiper-button-next{
				width: 12%;
				right:35%;
			}
		}
	}
`;

const Card = styled.div`
	width: 300px;
	margin-right: 20px;
	height: auto;
	z-index: 2;

	img {
		width: 300px;
		height: 270px;
	}
`;

const Texts = styled.div`
	padding: 0.7rem;
	background: #142251;
	color: #fff;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;

	h5 {
		margin-bottom: 10px;
		font-size: 18px;
		font-weight: 500;
		line-height: 17px;
	}

	p {
		margin: 0;
		color: #fff;
		font-weight: 200;
		display: flex;
		align-items: center;
	}
`;

const News = ({ items }) => {
	const breakpoints = {
		// when window width is >= 320px
		320: {
			slidesPerView: 1,
			spaceBetween: 20,
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		// when window width is >= 640px
		640: {
			slidesPerView: 2,
			spaceBetween: 25,
		},
		840: {
			slidesPerView: 3,
			spaceBetween: 15,
		},
	};
	return (
		<Container id="news">
			<div style={{ textAlign: "center" }}>
				<h2>News</h2>
				<p>Keep up with news on oil from all around the world.....</p>
			</div>
			{/* <div style={{ position: "relative" }}>
				<Arrow style={{ position: "absolute", right: 0 }} />{" "}
			</div> */}

			<Wrapper>
				{/* <Dots
					style={{
						zIndex: 1,
						position: "absolute",
						left: "80px",
						bottom: "20px",
					}}
				/> */}
				{/* <Red
					style={{
						zIndex: 1,
						position: "absolute",
						right: "70px",
						bottom: "-14rem",
					}}
				/> */}
				<Swiper
					modules={[A11y, Autoplay, Navigation]}
					spaceBetween={25}
					slidesPerView={3}
					navigation={true}
					autoplay={true}
					animation={true}
					effect="flip"
					loop={true}
					centeredSlides={true}
					breakpoints={breakpoints}
				>
					{items &&
						items.map((item, index) => {
							const { link, date, title } = item;
							const first = title.split("[");
							const second = first[2].split("]");
							console.log(second[0]);
							const formatDate = date.split("T");
							return (
								<SwiperSlide key={index}>
									<a href={link} target="_blank" rel="noreferrer">
										<h6>{second[0]}</h6>
										<p>{formatDate[0]}</p>
									</a>
								</SwiperSlide>
							);
						})}
				</Swiper>
			</Wrapper>
		</Container>
	);
};

export default News;
