import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

//svgs
import { ReactComponent as Check } from '../../assets/check.svg'

//components
import Sidebar from '../../components/DashboardSidebars/Client'
import Topbar from '../../components/DashboardTopbars/Client'

//styles
const Container = styled.section`
    display: flex;
    height: 100vh;
    overflow: hidden;
`

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-left: 200px;
    overflow: hidden;

    @media screen and (max-width: 600px) {
        padding-left: 0;
    }
`


const Board = styled.div`
    background: #F0F0F7;
    padding: 1rem 2rem 2rem 5rem;  
    height: 100%;
    overflow-y: scroll;
    overflow-x: scroll;

    @media screen and (max-width: 600px) {
        padding: 1rem 2rem 2rem 2rem;
    }

    h2 {
        font-weight: 200;
        padding: 1.5rem 0;

        @media screen and (max-width: 920px) {
            margin-bottom: 15px;
        }
    } 
`

const Info = styled.div`
    display: flex;
    background: #fff;
`

const Space = styled.div`
    padding: .5rem;
    width: 200px;
    border-right: 1px solid #E9E9F0;

    @media screen and (max-width: 750px) {
        display: none;
    }
`
const Back = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #3B86FF;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
`

const Details = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 6rem 20px 2rem;
    overflow: hidden;
    width: 100%;

    @media screen and (max-width: 1100px) {
       padding: 20px 2rem;
    }

    @media screen and (max-width: 950px) {
        width: 100%;
    }
    
    @media screen and (max-width: 350px) {
        padding: 20px 1rem;
    }
    
    h3 {
        font-weight: 400;
    }
`

const Groups = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    margin-top: 3rem;
    width: 100%;
    overflow: hidden;
    border: 1px solid #CACED5;

    h2 {
        color: #003468;
        text-align: center;

        @media screen and (max-width: 350px) {
            font-size: 18px;
        }
    }

    svg {
        margin: 0 0 2rem 0;
    }
`

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    /* width: 150px; */
    background: #003468;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;

    @media screen and (max-width: 350px) {
        font-size: 12px;
        padding: 8px;
    }
`

const UploadSuccess = () => {
    let history = useHistory();  
    
    useEffect(() => {
        const user = localStorage.getItem('token');

        if (user === 'undefined' || !user) {
            history.push('/login')
        } 
    }, [])
    
    return (
        <Container>
            <Sidebar />
            <Contents>
                <Topbar />

                <Board>
                    <h2>Secured Document Exchange Portal</h2>
                    
                    <Info>
                        <Space> 
                            <Back to='/dashboard'>BACK</Back>
                        </Space>

                        <Details>
                            <h3>Upload Documents</h3>

                            <Groups>
                                <h2>DOCUMENTS UPLOADED SUCCESSFULLY!</h2>
                                <Check style={{height: '150px'}} />
                                <Button to='/dashboard/upload'>UPLOAD NEW REQUEST</Button>
                            </Groups>
                        </Details>
                    </Info>
                </Board>
            </Contents>
        </Container>
    )
}

export default UploadSuccess
