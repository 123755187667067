import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import bg from '../assets/tankbg.png'
import { ReactComponent as Check } from '../assets/check.svg'

//styles
const Container = styled.section`
    display: flex;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`

const Bg = styled.div`
    background-image: url(${bg});
    background-size: 100% 100%;
    width: 50%;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 500px;
    }
`

const Group = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 2rem;
    position: relative;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 400px;
    }

    @media screen and (max-width: 768px) {
    padding: 4rem 1rem 10px 1rem;
    }

    h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 10px;
        letter-spacing: 5px;
    }

    p {
        text-align: center;
        font-size: 13px;
        margin-bottom: 30px;
        color: #707070;
    }
`

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 12px;
    color: #fff;
    width: 120px;
    font-size: 13px;
    border-radius: 3px;
    background: #43425D;
    margin-top: 15px;
`

const Powered = styled.section`
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;

    p {
        margin: 0;
        color: #43425D;
        font-size: 12px;
    }
`

const Success = () => {
    return (
        <Container>
            <Bg />
            <Group>
                <h2>Kephren</h2>
                <p>Account Created Successfully!</p>
                <Check />
                <Button to='/login'>Login</Button>
                
                <Powered>
                    <p>Powered by Petrong Software Solutions</p>
                    <p>Pacioli</p>
                </Powered>
            </Group>

        </Container>
    )
}

export default Success
