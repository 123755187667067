import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import document from "../../assets/file.jpg";
import ReactLoading from "react-loading";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 2px 0;
`;

const Btns = styled.div`
	display: grid;
	grid-template-columns: 195px 120px 185px;
	border-bottom: 1px solid #e9e9f0;
	border-right: none;
	border-left: none;
	color: #43425d;
	padding: 0 15px;

	@media screen and (max-width: 860px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 15px;
		padding: 0;
	}

	@media screen and (max-width: 320px) {
		grid-template-columns: 1fr;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 15px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	outline: none;
	border-left: 1px solid #e9e9f0;
	border-right: 1px solid #e9e9f0;
	border-top: 1px solid #e9e9f0;
	border-bottom: none;
	margin-right: 5px;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;

	@media screen and (max-width: 860px) {
		margin-right: 0;
	}
`;

const Details = styled.div`
	i {
		height: 100px;
		width: 100px;
		margin: 1rem 0;
		color: #3b86ff;
		font-size: 40px;
		padding: 0.7rem 2rem;

		@media screen and (max-width: 450px) {
			height: 100px;
			width: 120px !important;
		}
	}
`;

const Group = styled.div`
	display: flex;
	padding: 25px 0 10px 15px;
	border-bottom: 1px solid #e9e9f0;

	@media screen and (max-width: 860px) {
		padding: 40px 15px 15px 15px;
	}

	@media screen and (max-width: 555px) {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 30px;
	}

	div {
		display: flex;
		flex-direction: column;
		width: 400px;

		h3 {
			font-size: 17px;
			font-weight: 400;
			color: #4d4f5c;
			margin-bottom: 10px;
		}

		h5 {
			color: #43425d;
			font-size: 14px;
			font-weight: 400;
		}
	}
`;

const Load = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width: 100%;
`;

const SentDocDetails = ({ docInfo }) => {
	const [toggle, setToggle] = useState(1);

	const changeTab = (index) => {
		setToggle(index);
	};

	// console.log(docInfo);

	return (
		<>
			<Container>
				{!docInfo ? (
					<Load>
						<ReactLoading type={"spin"} color="#4D4F5C" />
					</Load>
				) : (
					<>
						<Btns>
							<Button
								className={toggle === 1 ? "btn active-btn" : "btn"}
								onClick={() => changeTab(1)}
							>
								Document Description
							</Button>
							{/* <Button
									className={toggle === 2 ? "btn active-btn" : "btn"}
									onClick={() => changeTab(2)}
								>
									Documents
								</Button> */}
							{/* <Button className={ toggle === 3 ? 'btn active-btn' : 'btn'} onClick={() => changeTab(3)}>Previous Documents</Button>                 */}
						</Btns>

						<Details
							className={toggle === 1 ? "content active-content" : "content"}
						>
							<Group>
								<div>
									<h3>Title</h3>
									<h5>{docInfo.title}</h5>
								</div>

								{/* <div>
										<h3>Status</h3>
										<h5>{docInfo[doc].receiver_status}</h5>
									</div> */}
							</Group>

							<Group>
								<div>
									<h3>Description</h3>
									<h5>{docInfo.description}</h5>
								</div>
							</Group>
						</Details>

						<Details
							className={toggle === 1 ? "content active-content" : "content"}
						>
							<a
								href={`https://kephren-api.petrongsoftware.com/api/user/download-sent-doc/${docInfo.filenames}`}
								target="_blank"
								rel="noreferrer"
								download={`${docInfo.filenames}`}
							>
								<i class="fas fa-folder"></i>
								{/* <img
									src={document}
									alt="document thumbmail"
									style={{ cursor: "pointer" }}
								/> */}
							</a>
							{/* <img src={document} alt='document thumbmail' style={{cursor: 'pointer'}} /> */}
						</Details>

						<Details
							className={toggle === 3 ? "content active-content" : "content"}
						>
							Previous Documents
						</Details>
					</>
				)}
			</Container>
		</>
	);
};

export default SentDocDetails;
