import React from "react";
// import { Link } from 'react-router-dom'
import styled from "styled-components/macro";
import { ReactComponent as Arrow } from "../../assets/galleryarrow.svg";
import image1 from "../../assets/news1.png";
import image2 from "../../assets/news2.png";
import image3 from "../../assets/gallery3.png";
import image4 from "../../assets/news1.png";
import image5 from "../../assets/gallery5.png";
import { ReactComponent as Dots } from "../../assets/greydot-news.svg";
import { ReactComponent as Bg } from "../../assets/gallerybg.svg";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { A11y, Autoplay, Navigation } from "swiper";

//styles
const Container = styled.section`
	display: flex;
	flex-direction: column;
	padding: 6rem 10rem;
	position: relative;

	@media screen and (max-width: 1200px) {
		padding: 6rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 6rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 6rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 2rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 6rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 6rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 6rem 2rem;
	}

	h2 {
		position: relative;
		margin-bottom: 25px;

		&:before {
			content: "";
			position: absolute;
			bottom: -15px;
			height: 3px;
			background: rgb(249,37,36);
			width: 55px;
		}
	}

	p {
		color: #7a839e;
		font-size: 13px;
		margin-bottom: 15px;
		
		position: relative;
		z-index: 2;

		@media screen and (max-width: 1160px) {
			width: 100%;
		}
	}
`;

const Wrapper = styled.section`
	display: flex;
	/* padding: 6rem 0 0 10rem; */
	margin-top: 5rem;
	height: auto;
	overflow-x: scroll;

	img {
		width: 300px;
		height: 250px;
		margin-right: 20px;
		z-index: 2;
	}
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Gallery = () => {
	const breakpoints = {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
				spaceBetween: 20,
			},
			// when window width is >= 480px
			480: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 3,
				spaceBetween: 15,
			},
	};
	return (
		<Container id="gallery">
			<div style={{ textAlign: "center" }}>
			<h2>Gallery</h2>
			<p>Our gallery holds images that depicts our services....</p>
			</div>

			<Wrapper>
				<Dots
					style={{
						zIndex: 1,
						position: "absolute",
						left: "120px",
						bottom: "10px",
					}}
				/>
				<Bg
					style={{
						zIndex: 1,
						position: "absolute",
						right: 0,
						bottom: "-100px",
					}}
				/>
				<Swiper
					modules={[A11y, Autoplay, Navigation]}
					spaceBetween={25}
					slidesPerView={3}
					autoplay={true}
					animation={true}
					effect="flip"
					loop={true}
					centeredSlides={true}
					breakpoints={breakpoints}
				>
					<SwiperSlide>
						<img src={image1} alt="thumbmail" />
					</SwiperSlide>
					<SwiperSlide>
						<img src={image2} alt="thumbmail" />
					</SwiperSlide>
					<SwiperSlide>
						<img src={image3} alt="thumbmail" />
					</SwiperSlide>
					<SwiperSlide>
						<img src={image4} alt="thumbmail" />
					</SwiperSlide>
					<SwiperSlide>
						<img src={image5} alt="thumbmail" />
					</SwiperSlide>
				</Swiper>
			</Wrapper>
		</Container>
	);
};

export default Gallery;
