import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    html, body {
        width: 100vw;
        overflow-x: hidden;
    }

    a {
        text-decoration: none;
        color: #fff;
    }
    .ReactModal__Content {
        padding: 0.5rem 1rem !important;
    }
`;

export default GlobalStyle;
