import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import logo from "../../assets/logo.png";
import "./styles.css";
import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import Sidebar from "../Sidebar/Sidebar";
import Scrollspy from "react-scrollspy";
import axios from "axios";


//styles
const Container = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 85px;
	width: 100%;
	z-index: 10;
	background: transparent;
	position: fixed;
	top: 0;
	padding: 1rem 10rem;

	@media screen and (max-width: 1200px) {
		padding: 1rem 8rem;
	}
	@media screen and (max-width: 1000px) {
		padding: 1rem 7rem;
	}

	@media screen and (max-width: 920px) {
		padding: 1rem 6rem;
	}
	@media screen and (max-width: 768px) {
		padding: 1rem 5rem;
	}
	@media screen and (max-width: 600px) {
		padding: 1rem 4rem;
	}
	@media screen and (max-width: 500px) {
		padding: 1rem 3rem;
	}

	@media screen and (max-width: 450px) {
		padding: 1rem 2rem;
	}
`;

const Logo = styled(Link)`
	height: 50px;

	img {
		height: 100%;
	}
`;

const MenuIcon = styled.div`
	display: none;

	@media screen and (max-width: 990px) {
		display: flex;
	}
`;

const Menu = styled(AiOutlineMenu)`
	width: 35px;
	height: 35px;
	color: #142251;
`;

const Close = styled(AiOutlineClose)`
	width: 35px;
	height: 35px;
	color: #142251;
`;

const MenuLink = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
	font-size: 14px;
	padding: 8px 10px;
	cursor: pointer;
	position: relative;

	a {
		color: #000;
	}

	@media screen and (max-width: 1300px) {
		margin-right: 10px;
		padding: 8px 8px;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 2rem;
	background: #142251;
	border-radius: 3px;
	font-size: 14px;
	color: #ffffff;
	border: none;
	outline: none;
	cursor: pointer;
`;

const Navbar = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [navbar, setNavbar] = useState(false);

	// const [email, setEmail] = useState("quadratwemimo@gmail.com");
	// const [password, setPassword] = useState("success01");
	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const showBackground = () => {
		if (window.scrollY >= 45) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};
	let admin = localStorage.getItem("admin-token");
	let user = localStorage.getItem("token");
	window.addEventListener("scroll", showBackground);
	const history = useHistory()
const NewForm = () => {
	const onSubmit = (e)=>{
		e.preventDefault();
		let form = document.getElementById("temp-login");
		let formData = new FormData(form);
		console.log(formData)
		axios
			.post("user/login", {email:"quadratwemimo@gmail.com", password: "success01."})
			.then((response) => {
				// console.log(response.data);

				localStorage.setItem("token", response.data.token);
				localStorage.setItem("user", JSON.stringify(response.data.user));

				if (response.data.error) {
					// toast.error("Email or Password Incorrect");
					// setLoading(false);
				} else if (response.data.token) {
					history.push("/dashboard");
				}
			})
			.catch((error) => {
				console.log(error);
				// if (error) {
				// 	setLoading(false);
				// 	toast.error("Server error, try again later");
				// }
			});
	}
	
	return(
		<form id="temp-login" onSubmit={onSubmit}> 
			<input type="hidden" name="email" value="quadratwemimo@gmail.com" />
			<input type="hidden" name="password" value="success01" />
			<Button type="submit">DocEx</Button>
		</form>
	)
}
	return (
		<>
			<Container className={navbar ? "navbar active" : null}>
				<Logo to="/">
					{" "}
					<img src={logo} alt="logo" />{" "}
				</Logo>

				<MenuIcon onClick={toggle}> {isOpen ? <Close /> : <Menu />} </MenuIcon>

				<Scrollspy
					className="scrollspy"
					items={["home", "about", "services", "news", "gallery", "contact"]}
					currentClassName="isCurrent"
				>
					<MenuLink onClick={() => window.location.replace("#home")}>
						<a href="#home">Home </a>
					</MenuLink>
					<MenuLink onClick={() => window.location.replace("#about")}>
						<a href="#about">About Us </a>
					</MenuLink>
					<MenuLink onClick={() => window.location.replace("#services")}>
						<a href="#services">Our Services </a>
					</MenuLink>
					<MenuLink onClick={() => window.location.replace("#news")}>
						<a href="#news">News </a>
					</MenuLink>
					<MenuLink onClick={() => window.location.replace("#gallery")}>
						<a href="#gallery">Gallery </a>
					</MenuLink>
					<MenuLink onClick={() => window.location.replace("#contact")}>
						<a href="#contact">Contact Us </a>
					</MenuLink>
					{/* {admin ? (
						<Button to="/admin">Admin</Button>
					) : user ? (
						<Button to="/dashboard">Profile</Button>
					) : (
						<Button to="/login">Log in</Button>
					)} */}
					<NewForm />
				</Scrollspy>
			</Container>
			<Sidebar isOpen={isOpen} toggle={toggle} />
		</>
	);
};

export default Navbar;
