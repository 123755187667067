import { useEffect, useState } from "react";
import axios from "axios";
//components
import Navbar from "../components/Navbar/Navbar";
import Home from "../components/Home/Home";
import About from "../components/About/About";
import Services from "../components/Services/Services";
import News from "../components/News/News";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";

const Homepage = () => {
	const [rssUrl, setRssUrl] = useState("");
	const [items, setItems] = useState([]);

	useEffect(() => {
		setRssUrl("https://www.spglobal.com/platts/en/rss-feed/oil");
	}, []);
	useEffect(() => {
		const getRss = async () => {
			const urlRegex =
				/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
			if (!urlRegex.test(rssUrl)) {
				return;
			}
			const res = await fetch(`https://api.allorigins.win/get?url=${rssUrl}`);
			const { contents } = await res.json();
			// console.log(contents);
			const feed = new window.DOMParser().parseFromString(contents, "text/xml");
			const items = feed.querySelectorAll("item");
			const feedItems = [...items].map((el) => ({
				link: el.querySelector("link")?.innerHTML,
				title: el.querySelector("title")?.innerHTML,
				author: el.querySelector("author")?.innerHTML,
				date: el.querySelector("pubDate")?.innerHTML,
			}));
			console.log(feedItems);
			setItems(feedItems);
		};
		getRss();
	}, [rssUrl]);
	useEffect(() => {
		console.log(items);
	}, [items]);
	return (
		<>
			<Navbar />
			<Home />
			<About />
			<Services />
			<News items={items} />
			<Gallery />
			<Footer />
		</>
	);
};

export default Homepage;
