import axios from "axios";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//components
import Topbar from "../../components/DashboardTopbars/Admin";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 2rem 5rem;
	height: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}

	h2 {
		font-weight: 200;
		padding: 1.5rem 0;

		@media screen and (max-width: 920px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	background: #fff;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 15rem 20px 2rem;
	overflow: hidden;
	width: 100%;

	@media screen and (max-width: 1100px) {
		padding: 20px 2rem;
	}

	@media screen and (max-width: 950px) {
		width: 100%;
	}

	@media screen and (max-width: 350px) {
		padding: 20px 1rem;
	}

	h3 {
		font-weight: 400;
	}
`;

const Groups = styled.form`
	display: flex;
	flex-direction: column;
	padding: 1rem 1rem 4rem 8rem;
	margin-top: 3rem;
	width: 100%;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 900px) {
		padding: 1rem 1rem 4rem 0;
	}

	@media screen and (max-width: 360px) {
		padding: 0 0 4rem 0;
	}

	input {
		border: 1px solid #e8e9ec;
		outline: none;
		padding: 8px 20px;
		color: #000;
		font-size: 14px;
		margin-bottom: 15px;
	}
`;

const Button = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	width: 150px;
	background: #3b86ff;
	font-size: 13px;
	height: 35px;
	border-radius: 3px;
	position: absolute;
	right: 1rem;
	bottom: 1rem;
	color: #fff;
	outline: none;
	border: none;

	@media screen and (max-width: 350px) {
		font-size: 12px;
		padding: 8px;
	}
`;
const Load = styled.div`
	display: ${({ loading }) => (loading ? "flex" : "none")};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const AdminProfile = () => {
	let history = useHistory();
	const admin = JSON.parse(localStorage.getItem("admin"));
	const [loading, setLoading] = useState(false);
	const [profileInfo, setprofileInfo] = useState({
		
		email: admin.email,
		old_password: "",
		password: "",
		password_confirmation: "",
	});

	const updateProfile = () => {
		setLoading(true);
		axios
			.put(`/admin/update`, profileInfo, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => {
				// console.log(response);
				setLoading(false);
				toast.success("Update successful");
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				toast.error("Failed, try again");
			});
	};
	useEffect(() => {
		const user = localStorage.getItem("admin-token");
		if (user === "undefined" || !user) {
			history.push("/admin-login");
		}
	}, []);
	const handleChange = (e) => {
		setprofileInfo({ ...profileInfo, [e.target.name]: e.target.value });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (profileInfo.password === profileInfo.password_confirmation) {
			updateProfile();
		} else {
			toast.error("The two passwords are different");
		}
		// console.log(profileInfo);
	};
	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Secured Document Exchange Portal</h2>

					<Info>
						<Details>
							<h3>Update Profile</h3>

							<Groups onSubmit={handleSubmit}>
								{/* <input
									type="text"
									value={profileInfo.fullname}
									name="username"
									placeholder="EMAIL"
									// value={profileInfo.username}
									onChange={handleChange}
									disabled
								/> */}
								<input
									type="text"
									defaultValue={profileInfo.email}
									placeholder="NAME"
									name="fullname"
									// value={profileInfo.fullname}
									onChange={handleChange}
									disabled
								/>
								<input
									type="password"
									placeholder="OLD PASSWORD"
									value={profileInfo.old_password}
									name="old_password"
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="NEW PASSWORD"
									value={profileInfo.password}
									name="password"
									onChange={handleChange}
								/>
								<input
									type="password"
									placeholder="CONFIRM NEW PASSWORD"
									value={profileInfo.password_confirmation}
									name="password_confirmation"
									onChange={handleChange}
								/>
								<Button type="submit">Update</Button>
							</Groups>
							<Load loading={loading}>
								<ReactLoading type={"spin"} color="#4D4F5C" />
							</Load>
						</Details>
					</Info>
				</Board>
			</Contents>
			<ToastContainer
				className="toast"
				position="top-right"
				autoClose="4000"
				hideProgressBar="false"
				closeOnClick
			></ToastContainer>
		</Container>
	);
};

export default AdminProfile;
