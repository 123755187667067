import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const userToken = localStorage.getItem("token");
	const adminToken = localStorage.getItem("admin-token");

	const isLoggedIn = userToken || adminToken;

	const toast = "load toast";
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isLoggedIn) {
					return <Component {...props} />;
				} else {
					localStorage.setItem("toast", toast);
					return (
						<Redirect
							to={{
								pathname: "/login",
								state: {
									from: props.location,
								},
							}}
						/>
					);
				}
			}}
		/>
	);
};

// export default ProtectedRoute;
