import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import axios from "axios";

import { ReactComponent as View } from "../../assets/icons/search.svg";

//styles
const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;

	@media screen and (max-width: 1200px) {
		width: 800px;
	}
	tr {
		border-bottom: 1px solid #e9e9f0;
		width: 100%;
		&:first-of-type {
			background: #e9e9f0;
		}
	}
	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		text-align: start;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: start;
		font-size: 13px;

		
		a {
			color: white;
			text-decoration: none;
			button {
				padding: 0.5rem 1rem;
				background: #43425d;
				color: #fff;
				cursor: pointer;
				border-radius: 3px;
				border: none;
				outline: none;
			}
		}
	}
`;

const SentDoc = ({ docs, fileNo }) => {
	// console.log(docs);
	return (
		<Table>
			<tr>
				<th style={{paddingLeft: "2rem"}}>#</th>
				<th>TITLE</th>
				<th>DESCRIPTION</th>
				<th>FILENAME</th>
				{/* <th>OTHER INFO</th>   */}
				{/* <th>STATUS</th> */}
				<th>DATE</th>
				<th>ACTION</th>  
				{/* <th>ACTIONS</th> */}
			</tr>

			{docs
				.slice(0)
				.reverse()
				.map((doc, index) => {
					let date = doc.created_at.split(" ");
					return (
						<tr key={index}>
							<td style={{paddingLeft: "2rem"}}>{index + 1}</td>
							<td style={{textTransform: "capitalize"}}>
								
									{doc.title}
								
							</td>

							<td style={{textTransform: "capitalize"}}>{doc.description}</td>
							<td >
								{doc.filenames}
							</td>
							{/* <td>{doc.other_info}</td> */}
							{/* <td>{doc.sender_status}</td> */}
							<td>{date[0]}</td>
							<td>
														<div>
									<a
										href={`https://kephren-api.petrongsoftware.com/api/user/download-sent-doc/${doc?.filenames}`}
										target="_blank"
										rel="noreferrer"
										download={doc?.filenames}
									>
										<button>View File</button>
									</a>
								</div>
														</td>
						</tr>
					);
				})}
		</Table>
	);
};

export default SentDoc;
