import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import axios from "axios";

//svgs
import { ReactComponent as Prev } from "../../assets/icons/prev.svg";
import { ReactComponent as Next } from "../../assets/icons/next.svg";

//components
import Topbar from "../../components/DashboardTopbars/Admin";
import AdministratorsTable from "../../components/AdminTables/AdministratorsTable";

//styles
const Container = styled.section`
	display: flex;
	height: 100vh;
	overflow: hidden;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	padding-left: 200px;
	overflow: hidden;

	@media screen and (max-width: 600px) {
		padding-left: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	padding: 1rem 2rem 2rem 5rem;
	height: 100%;
	overflow-y: scroll;
	overflow-x: scroll;

	@media screen and (max-width: 600px) {
		padding: 1rem 2rem 2rem 2rem;
	}

	h2 {
		font-weight: 200;
		margin-bottom: 1rem;

		@media screen and (max-width: 1130px) {
			margin-bottom: 15px;
		}
	}
`;

const Info = styled.div`
	
	background: #fff;
	overflow: hidden;
`;

export const Space = styled.div`
	padding: 0.5rem 1.5rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	height: 100%;
	border-right: 1px solid #e9e9f0;
	@media only screen and (min-width: 769px) and (max-width: 1200px) {
		width: 25%;
	}
	@media screen and (max-width: 768px) {
		width: 25%;
	}
	@media screen and (max-width: 480px) {
		width: 100%;
		height: 5vh;
		border-right: none;
	}
`;
export const Upload = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.4rem;
	width: 15%;
	background: #43425d;
	font-size: 0.8rem;
	font-weight: 550;
	margin-right: 2rem;
	margin-top: 1rem;
	height: 35px;
	border-radius: 3px;
	color: white;
	text-decoration: none;

	@media only screen and (min-width: 769px) and (max-width: 1200px) {
		font-size: 0.7rem;
		font-weight: 550;
	}
	@media screen and (max-width: 480px) {
		width: 35%;
		margin: 0.8rem auto 0;
	}
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
	width: 100% ;

	@media screen and (max-width: 750px) {
		width: 100%;
	}

	h3 {
		font-weight: 400;
	}
`;

const Table = styled.div`
	margin-top: 15px;

	@media screen and (max-width: 1300px) {
		overflow-x: scroll;
		width: 100%;
	}
`;

const Pages = styled.div`
	display: flex;
	justify-content: end;

	&:last-child {
		margin-right: 0;
	}

	div {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 35px;
		color: #43425d;
		font-size: 14px;
		height: 35px;
		border-radius: 3px;
		border: 1px solid #e9e9f0;
		margin-right: 5px;
		cursor: pointer;
	}
`;

const Administrators = (props) => {
	let history = useHistory();

	const [admins, setAdmins] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(true);
	// console.log(isEditing);
	const adminInfo = JSON.parse(localStorage.getItem("admin"));
	const fetchAdmin = () => {
		axios
			.get("admin/getall", {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("admin-token"),
				},
			})
			.then((response) => {
				setAdmins(response.data);
				// console.log(response);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				if (err) {
					history.push("/admin-login");
				}
			});
	};

	useEffect(() => {
		const user = localStorage.getItem("admin-token");

		if (user === "undefined" || !user) {
			history.push("/admin-login");
		}
	});

	useEffect(() => {
		fetchAdmin();
	}, []);
	
	return (
		<Container>
			<Contents>
				<Topbar />

				<Board>
					<h2>Administrators</h2>

					<Info>
					<Space>
					<h3>All Administrators</h3>
					{adminInfo?.role === "Super Admin" ? (
	<Upload to="/admin/administrators/new">NEW ADMIN</Upload>
						) : null}
					
					</Space>
					<Details>
					

						<Table>
							{" "}
							<AdministratorsTable
								admins={admins}
								fetchAdmin={fetchAdmin}
								loading={loading}
							/>{" "}
						</Table>

					</Details>
					</Info>
				</Board>
			</Contents>
		</Container>
	);
};

export default Administrators;
