import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyles";

//components
import {
	Homepage,
	SignIn,
	SignUp,
	Success,
	ForgotPassword,
	ResetPassword,
	CustomerDashboard,
	CustomerReceivedDoc,
	CustomerUniqueReceivedDoc,
	CustomerSentDoc,
	CustomerUniqueSentDoc,
	CustomerUploadDoc,
	UploadSuccess,
	CustomerProfile,
	CustomerHelp,
	AdminLogin,
	AdminForgotPassword,
	AdminDashboard,
	AdminReceivedDoc,
	AdminUniqueReceivedDoc,
	AdminSentDoc,
	AdminUniqueSentDoc,
	AdminUploadDoc,
	AdminUploadSuccess,
	Administrators,
	CreateAdmin,
	AdminProfile,
	AdminHelp,
} from "./pages";
import { ProtectedRoute } from "./ProtectedRoute";
import { AdminProtectedRoute } from "./AdminProtectedRoute";
import { NotFound } from "./NotFound";
import { LoginRedirectRoute } from "./LoginRedirectRoute";
import { AdminLoginRedirectRoute } from "./AdminLoginRedirectRoute";

function App() {
	return (
		<>
			<Router>
				<GlobalStyle />

				<Switch>
					<Route path="/" exact component={Homepage} />
					<LoginRedirectRoute path="/login" exact component={SignIn} />
					<Route path="/signup" exact component={SignUp} />
					<Route path="/Success" exact component={Success} />
					<Route path="/forgot-password" exact component={ForgotPassword} />
					<Route path="/reset-password" exact component={ResetPassword} />
					<ProtectedRoute
						path="/dashboard"
						exact
						component={CustomerDashboard}
					/>
					<ProtectedRoute
						path="/dashboard/received"
						exact
						component={CustomerReceivedDoc}
					/>
					<ProtectedRoute
						path={`/dashboard/received/:id`}
						exact
						component={CustomerUniqueReceivedDoc}
					/>
					<ProtectedRoute
						path="/dashboard/sent"
						exact
						component={CustomerSentDoc}
					/>
					<ProtectedRoute
						path={`/dashboard/sent/:id`}
						exact
						component={CustomerUniqueSentDoc}
					/>
					<ProtectedRoute
						path="/dashboard/upload"
						exact
						component={CustomerUploadDoc}
					/>
					<ProtectedRoute
						path="/dashboard/upload/success"
						exact
						component={UploadSuccess}
					/>
					<ProtectedRoute
						path="/dashboard/profile"
						exact
						component={CustomerProfile}
					/>
					<ProtectedRoute
						path="/dashboard/help"
						exact
						component={CustomerHelp}
					/>

					<AdminLoginRedirectRoute
						path="/admin-login"
						exact
						component={AdminLogin}
					/>
					<Route
						path="/admin-forgot-password"
						exact
						component={AdminForgotPassword}
					/>
					<AdminProtectedRoute path="/admin" exact component={AdminDashboard} />
					<AdminProtectedRoute
						path="/admin/received"
						exact
						component={AdminReceivedDoc}
					/>
					<AdminProtectedRoute
						path={`/admin/received/:id`}
						exact
						component={AdminUniqueReceivedDoc}
					/>
					<AdminProtectedRoute
						path="/admin/sent"
						exact
						component={AdminSentDoc}
					/>
					<AdminProtectedRoute
						path={`/admin/sent/:id`}
						exact
						component={AdminUniqueSentDoc}
					/>
					<AdminProtectedRoute
						path="/admin/upload"
						exact
						component={AdminUploadDoc}
					/>
					<AdminProtectedRoute
						path="/admin/upload/success"
						exact
						component={AdminUploadSuccess}
					/>
					<AdminProtectedRoute
						path="/admin/administrators"
						exact
						component={Administrators}
					/>
					<AdminProtectedRoute
						path="/admin/administrators/create"
						exact
						component={CreateAdmin}
					/>
					<AdminProtectedRoute
						path="/admin/administrators/edit/:id"
						exact
						component={CreateAdmin}
					/>
					<AdminProtectedRoute
						path="/admin/profile"
						exact
						component={AdminProfile}
					/>
					<AdminProtectedRoute path="/admin/help" exact component={AdminHelp} />
					<Route exact path="*" component={NotFound} />
				</Switch>
			</Router>
		</>
	);
}

export default App;
