import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import axios from "axios";
import ReactLoading from "react-loading";

//components
import Topbar from "../../components/DashboardTopbars/Client";

const Container = styled.section`
	display: flex;
	height: 100vh;
`;

const Contents = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 200px;

	@media screen and (max-width: 600px) {
		padding: 0;
	}
`;

const Board = styled.div`
	background: #f0f0f7;
	height: 100%;
	padding: 2rem 2rem 2rem 5rem;
	overflow: scroll;

	@media screen and (max-width: 600px) {
		padding: 2rem;
	}

	h2 {
		margin-bottom: 20px;
		font-weight: 500;

		@media screen and (max-width: 350px) {
			font-size: 18px;
		}
	}
`;

const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-bottom: 40px;

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}

	div {
		display: flex;
		flex-direction: column;
		padding: 1rem;
		background: #fff;

		h5 {
			font-size: 14px;
			font-weight: 300;
			color: #000;
			height: 50px;
		}

		h3 {
			color: #43425d;
		}
	}
`;

const btnstyle = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	border-radius: 3px;
	cursor: pointer;
	color: #fff;
	background: transparent;
	margin-right: 15px;
	font-size: 13px;

	@media screen and (max-width: 385px) {
		margin-bottom: 15px;
		margin-right: 0;
	}
`;

const Btns = styled.div`
	display: flex;

	@media screen and (max-width: 385px) {
		flex-direction: column;
	}
`;

const Button = styled(Link)`
	${btnstyle};
`;

const CustomerDashboard = () => {
	let history = useHistory();

	const [no_rec_docs, setno_rec_docs] = useState("");
	const [no_sent_docs, setno_sent_docs] = useState("");
	const userInfo = JSON.parse(localStorage.getItem("user"));
	const id = userInfo?.id;
	const rec = () => {
		axios
			.get(`/user/getuser-received-docs/${id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
			.then((response) => {
				setno_rec_docs(response.data.received_documents.length);
			})
			.catch((err) => {
				console.log(err);
				if (err) {
					history.push("/login");
				}
			});
	};

	const sent = () => {
		axios
			.get(`/user/getuser-sent-docs/${id}`, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			})
			.then((response) => {
				// console.log(response);
				setno_sent_docs(response.data.sent_documents.length);
			})
			.catch((err) => {
				console.log(err);
				if (err) {
					history.push("/login");
				}
			});
	};

	useEffect(() => {
		rec();
	}, []);

	useEffect(() => {
		sent();
	}, []);

	const total_docs = no_rec_docs + no_sent_docs;

	useEffect(() => {
		const user = localStorage.getItem("token");

		if (user === "undefined" || !user) {
			history.push("/login");
		}
	}, []);

	return (
		<Container className="dashboard">
			<Contents>
				<Topbar />

				<Board>
					<h2>Ministry of Finance - Secure Document Exchange Portal</h2>

					<Info>
						<div>
							<h5>Total No of Documents Sent</h5>

							{no_sent_docs === 0 ? (
								<h3>0</h3>
							) : !no_sent_docs ? (
								<ReactLoading type={"spin"} color="#4D4F5C" />
							) : (
								<h3>{no_sent_docs}</h3>
							)}
						</div>

						<div>
							<h5>Total No of Documents Received</h5>

							{no_rec_docs === 0 ? (
								<h3>0</h3>
							) : !no_rec_docs ? (
								<ReactLoading type={"spin"} color="#4D4F5C" />
							) : (
								<h3>{no_rec_docs}</h3>
							)}
						</div>

						<div>
							<h5>Total No of Documents</h5>

							{total_docs === 0 ? (
								<h3>0</h3>
							) : !total_docs ? (
								<ReactLoading
									type={"spin"}
									color="#4D4F5C"
									style={{ height: "30px", width: "50px" }}
								/>
							) : (
								<h3>{total_docs}</h3>
							)}
						</div>
					</Info>
					<h2>Welcome, {userInfo.company}</h2>
					<Btns>
						<Button to="/dashboard/upload" style={{ background: "#3B86FF" }}>
							UPLOAD DOCUMENT
						</Button>
						<Button to="/dashboard/received" style={{ background: "#43425D" }}>
							VIEW DOCUMENTS
						</Button>
					</Btns>
				</Board>
			</Contents>
		</Container>
	);
};

export default CustomerDashboard;
