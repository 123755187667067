import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

import { ReactComponent as View } from "../../assets/icons/search.svg";

//styles
const Table = styled.table`
	border-collapse: collapse;
	margin-bottom: 15px;
	width: 100%;

	@media screen and (max-width: 1200px) {
		width: 800px;
	}
	tr {
		border-bottom: 1px solid #e9e9f0;
		width: 100%;
		&:first-of-type {
			background: #e9e9f0;
		}
	}
	th {
		background: #e9e9f0;
		color: #808495;
		padding: 8px;
		font-weight: 400;
		font-size: 14px;
		text-align: start;
		border: none;
	}

	td {
		padding: 8px;
		color: #43425d;
		text-align: start;
		font-size: 13px;

		
		a {
			color: white;
			text-decoration: none;
			button {
				padding: 0.5rem 1rem;
				background: #43425d;
				color: #fff;
				cursor: pointer;
				border-radius: 3px;
				border: none;
				outline: none;
			}
		}
	}
`;


const SentDocTables = ({ docs }) => {
	return (
		<Table>
			<tr>
				<th style={{paddingLeft: "2rem"}}>#</th>
				<th>TITLE</th>
				<th>DESCRIPTION</th>
				<th>FILENAMES</th>
				{/* <th>STATUS</th> */}
				<th>DATE</th>
				<th>ACTION</th>
			</tr>

			{docs
				.slice(0)
				.reverse()
				.map((docu, index) => {
					// console.log(docs);
					// console.log(docu.docId);
					let date = docu.created_at.split(" ");
					return (
						<tr key={index}>
							<td style={{paddingLeft: "2rem"}}>{index + 1}</td>
							<td style={{textTransform: "capitalize"}}>
								
								
								
									{docu.title}
								
							</td>
							<td style={{textTransform: "capitalize"}}>{docu.description}</td>
							<td>
								{docu.filenames}
							</td>
							{/* <td>{docu.sender_status}</td> */}
							<td>{date[0]}</td>
							<td>
														<div>
									<a
										href={`https://kephren-api.petrongsoftware.com/api/user/download-sent-doc/${docu?.filenames}`}
										target="_blank"
										rel="noreferrer"
										download={docu?.filenames}
									>
										<button>View File</button>
									</a>
								</div>
														</td>
						</tr>
					);
				})}
		</Table>
	);
};

export default SentDocTables;
